/* Breadcrumbs
  =============== */

// Breadcrumbs
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 0;
  margin-bottom: 0;
  list-style: none;
  font-size: .875rem;
}

.breadcrumb-item {
  a, a:visited {
    color: $ph-grey;
  }

  a:hover, a:active {
    color: $ph-alert-blue;
  }
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: .25rem;

  &::before {
    display: inline-block; // Suppress underlining of the separator in modern browsers
    padding-right: .25rem;
    color: $ph-dark-grey;
    content: "/";
  }
  }
 
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $ph-dark-grey;
  }
}