/**
 * Grid
 */
 $column-padding: 1rem;
 $margin-bottom: 1rem;
 
 // The rows have a negative margin  which are offset by the padding on the columns.
 %column-padding {
   padding-left: $column-padding;
   padding-right: $column-padding;
 }
 
 %row-margin {
   margin-left: -$column-padding;
   margin-right: -$column-padding;
 }
 
 // Flex row
 .flex-row {
   @extend %row-margin;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
 }
 
 .flex-small,
 .flex-large {
   @extend %column-padding;
   flex-basis: 100%;
   margin-bottom: $margin-bottom;
 }
 
 /* Small screen breakpoint */
 
 @include small-breakpoint {
   .flex-small {
     flex: 1;
     margin-bottom: 0;
   }
 }
 
 /* Large screen breakpoint */
 
 @include large-breakpoint {
   .flex-large {
     flex: 1;
     margin-bottom: 0;
   }
 }


//
// Block Grid Variables
//
$include-html-block-grid-classes: true !default;
$include-xl-html-block-grid-classes: false !default;

// We use this to control the maximum number of block grid elements per row
$block-grid-elements: 12 !default;
$block-grid-default-spacing: rem-calc(20) !default;

$align-block-grid-to-grid: false !default;
@if $align-block-grid-to-grid {
  $block-grid-default-spacing: rem-calc(30) !default;
}

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
$block-grid-media-queries: true !default;

//
// Block Grid Mixins
//

// Create a custom block grid
//
// $per-row - # of items to display per row. Default: false.
// $spacing - # of ems to use as padding on each block item. Default: rem-calc(20).
// $include-spacing - Adds padding to our list item. Default: true.
// $base-style - Apply a base style to block grid. Default: true.
@mixin block-grid(
  $per-row:false,
  $spacing:$block-grid-default-spacing,
  $include-spacing:true,
  $base-style:true) {

  @if $base-style {
    display: block;
    padding: 0;
    @if $align-block-grid-to-grid {
      margin: 0;
    } @else {
      margin: 0 (-$spacing/2);
    }
    @include clearfix;

    > li,
    > article {
      display: block;
      float: $default-float;
      height: auto;
      @if $include-spacing {
        padding: ($spacing/2) ($spacing/2);
      }
    }
  }

@if $per-row {
   > li,
   > article {
     list-style: none;
     @if $include-spacing {
       padding: ($spacing/2) ($spacing/2);
     }
     width: 100%/$per-row;
     &:nth-of-type(1n) { clear: none; }
     &:nth-of-type(#{$per-row}n+1) { clear: both; }
     @if $align-block-grid-to-grid {
        @include block-grid-aligned($per-row, $spacing);
     }
   }
 }
}

@mixin block-grid-aligned($per-row, $spacing) {
  @for $i from 1 through $block-grid-elements {
    @if $per-row >= $i {
      $grid-column: '+' + $i;
      @if $per-row == $i {
        $grid-column: '';
      }
      &:nth-of-type(#{$per-row}n#{unquote($grid-column)}) {
        padding-left: ($spacing - (($spacing / $per-row) * ($per-row - ($i - 1))));
        padding-right: ($spacing - (($spacing / $per-row) * $i));
      }
    }
  }
}

// Generate presentational markup for block grid.
// $size - Name of class to use, i.e. "large" will generate .large-block-grid-1, .large-block-grid-2, etc.
@mixin block-grid-html-classes($size, $include-spacing) {
  @for $i from 1 through $block-grid-elements {
    .#{$size}-block-grid-#{($i)} {
      @include block-grid($i, $block-grid-default-spacing, $include-spacing, false);
    }
  }
}

@include exports("block-grid") {
  @if $include-html-block-grid-classes {

    [class*="block-grid-"] { @include block-grid; }

    @if $block-grid-media-queries {
      @media #{$small-up} {
        @include block-grid-html-classes($size:small, $include-spacing:false);
      }

      @media #{$medium-up} {
        @include block-grid-html-classes($size:medium, $include-spacing:false);
      }

      @media #{$large-up} {
        @include block-grid-html-classes($size:large, $include-spacing:false);
      }

      @if $include-xl-html-block-grid-classes {
        @media #{$xlarge-up} {
          @include block-grid-html-classes($size:xlarge, $include-spacing:false);
        }

        @media #{$xxlarge-up} {
          @include block-grid-html-classes($size:xxlarge, $include-spacing:false);
        }
      }
    }
  }
}

//Similar to lists, but for grids to adopt Bootstrap 4
[class*=ph-grid-] {
  @include block-grid(1, 1rem);
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    line-height: 1.25;
  }
}

.ph-grid {
  &-2 {
    @include block-grid(1, 1rem);

    @media #{$small-up} {
      @include block-grid(2);
    }
  }

  &-3 {
    @include block-grid(1, 1rem);

    @media #{$small-up} {
      @include block-grid(2);
    }
    
    @media #{$large-up} {
      @include block-grid(3);
    }
  }

  &-4 {
    @include block-grid(1, 1rem);

    @media #{$small-up} {
      @include block-grid(2);
    }

    @media #{$large-up} {
      @include block-grid(3, 1.25rem);
    }
    
    @media #{$xxlarge-up} {
      @include block-grid(4);
    }
  }

  //Nine across on desktop
  &-9 {
    @include block-grid(2);

    @media #{$small-up} {
      @include block-grid(3);
    }

    @media #{$medium-up} {
      @include block-grid(4);
    }

    @media #{$large-up} {
      @include block-grid(5);
    }

    @media #{$xlarge-up} {
      @include block-grid(6);
    }
    
    @media #{$xxlarge-up} {
      @include block-grid(9);
    }
  }
}