/* Lists
  =============== */
ul li a:hover {
  text-decoration-color: $ph-gold;
}

.hide-bullet {
  list-style-type: none;
}

[class*=ph-list__col-] {
  margin: 1.5rem 0;

  & > ul {
    list-style: none;
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 0;

    & > li {
      margin-bottom: .625rem;

      a:hover {
        border-bottom: solid 1px $ph-gold;
        text-decoration: none;
      }
    }
  }
} 

.ph-list {
  list-style: none;
  /*display: flex;
  flex-direction: column;*/

  // No need to set list-style: none; since .list-group is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;

  &__bulleted {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__lg {
    & > li {
      font-size: rem-calc(16);

      @media #{$medium-up} {
        font-size: rem-calc(18);
      }

      @media #{$large-up} {
        margin-bottom: rem-calc(10);
      }

      @media #{$xlarge-up} {
        margin-bottom: rem-calc(20);
      }
    }
  }
}

.ph-list__inline {
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    align-items: center;
    flex-direction: row;
}

// Interactive list items

.ph-list__item, 
  a.ph-list__item {
  width: 100%; // For `<button>`s (anchors become 100% by default though)
  color: $ph-dark-grey;
  // font-weight: 700;
  text-align: inherit; // For `<button>`s (anchors inherit)
  border: none;
  padding: .25rem 0;

  // Hover state
  &:hover,
  &:active {
    color: $ph-dark-grey;
    // text-decoration: underline;
  }

  &--border-bottom {
    border-bottom: 1px solid $ph-light-grey;
    padding:1rem;
  }
}

.icon {
    margin-right: .5rem;
}




/* Flexbox */

// .ph-list__inline {
//   display: block;

//   li
// }


// @media screen and (min-width: 600px){
//  .ph-list__inline {
//   -webkit-flex-direction: row;
//    flex-direction: row;
//    justify-content: start;
//  }
//  .ph-list__inline > * {
//    -webkit-flex: 1;
//    flex: 1;
//    justify-content: start;
//  }
//  .ph-list__item {
   
//  -webkit-order: -1;
//   order: -1;
//  }
// }

// @media screen and (max-width: 600px){
//   .ph-list__inline {
//    -webkit-flex-direction: column;
//    flex-direction: column;
//  }
// }


// Product Category List - small thumbnails

.ph-img-grid {
  width: 100%;

  &__list {
    margin:0;
    padding:0;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    list-style: none;
    // display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 150px;
    margin:.5rem .5rem;
    font-size: $sm-body-font-size;
    padding:0 .15rem;

    & a {
      // display: flex;
      // flex-direction: column;
      align-items: center;
    }

    & img {
      width: 100%;
      max-width: 90px;
      max-height: 90px;
      margin:0 auto 5px auto;
      display:block;

      &::after {
        content: "\A"; 
      }
    }
    // &:first-child {
    //   margin-left:0;
    // }
  }

}

// Parker Tops Application
.ph-tops {

& li {
  border:solid 2px transparent;
  transition: all .2s ease-in-out;
  width: 100%;
  
  &:hover {
    border:solid 2px $ph-gold;
  }
}

  & li a {
    font-size: 1rem;
  }

}



.specifications {
    padding:.5rem 0;

    &__item {
      vertical-align: middle;
      padding:.25rem .5rem !important;
    }
    &__description {
      width: 100%;
      float: left;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      font-weight: $font-weight-bold;
    }
    &__value {
      padding-left:.25rem;
      width: 100%;
      float: left;
    }

}


@media #{$xlarge-up} {
  .specifications { 
    flex-direction: row;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-wrap: wrap;

    &__item {
    }
    &__description {
      width: 50%;
    }
    &__value {
      width: 50%;
    }
  }
}