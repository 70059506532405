/* Breakpoints
  =============== */

// Media query for mobile first layout 
@mixin small-breakpoint {
    @media (min-width: #{$mobile}) {
      @content;
    }
  }
  
  // Break on tablet
  @mixin medium-breakpoint {
    @media (min-width: #{$tablet}) {
      @content;
    }
  }
  
  // Break on desktop
  @mixin large-breakpoint {
    @media (min-width: #{$desktop}) {
      @content;
    }
  }

  @mixin xl-breakpoint {
    @media (min-width: #{$xl}) {
      @content;
    }
  }


// @media only screen and (min-width:320px) {

// }
// @media only screen and (min-width:480px) {

// }
// @media only screen and (min-width:768px) {

// }
// @media only screen and (min-width:1024px) {

// }
// @media only screen and (min-width:1140px) {

// }
// @media only screen and (min-width:1280px) {

// }
// @media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

// }
