/* Accordions
  =============== */

.ph-accordion {
    margin: 0 auto;
    width: 100%;
    font-family: $font-family;
    background: $background;

    &__item {
        background: $background;
    }

    &__title {
        border-bottom: 1px solid $ph-medium-grey;
        font-size: $font-size;
        padding: 0.75rem .375rem;
        font-weight: 400;
        color: $ph-grey;
        cursor: pointer;
        transition: .3s ease;
        margin: 0;
        outline : none;
        display:block;

        &:focus {
          outline: 0;
        }

        &:after {
          float: right;
          margin: .15rem .375rem;
          font-family: $icon-font-family;
          font-size: $font-size;
          color: $ph-grey;
          content: " \f067"; //fa-plus
          font-weight: $icon-font-weight;
        }
    
        &.collapsed {
          border-bottom: 1px solid $ph-light-grey;
          color: $black;
          font-weight: 500;

          &:after {
            color: $black;
            content: '\f068'; //fa-minus
            font-weight: $icon-font-weight;
          }
        }

        &:hover {
            background: $alternate-background;
            color: $ph-dark-grey;

            &:after {
              color: $ph-dark-grey;
            }
        }

        &--open {
            border-bottom: 1px solid $ph-light-grey;
            cursor: pointer;
            color: $black;
            font-size: $font-size;
            font-weight: 500;
            margin: 0;
            padding: 0.75rem .375rem;
    
            &:after {
                float: right;
                margin: .15rem .5rem .15rem 0;
                font-family: $icon-font-family;
                font-size: $font-size;
                font-weight: $icon-font-weight;
                content: ' \f068'; //fa-minus
            }
        
            &.collapsed {
              &:after {
                content: '\f067'; //fa-plus
              }
            }
    
        }
    }

    &__content {
        border-bottom: 1px solid $ph-medium-grey;
        display: none;
        padding: 1rem 2rem 1.5rem .375rem;
        color: $ph-dark-grey;
        line-height: 1.6;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        &--open {
            display: block;
        }
    }
  }
  
