/* Browse Products - List & Grid
  =============== */ 

//   .ph-product-block {
//     overflow-y: none; 
//     height: auto;

//     @include large-breakpoint {
//         overflow-y: scroll; 
//         height: calc(1000px);
//     }
//   }

  .ph-browse-list-view {
    margin: .5rem auto;
    width: 100%;
    font-family: $font-family;
    background: $background;

    &__controls {
        display:flex;
        width: 100%;
        align-items:center;
        flex-direction: row-reverse;
    }

    &__sort-by-dropdown-container {
        display: flex;
        align-items:center;
        width: 250px;
    }

    &__sort-by-dropdown-label {
        margin: -7px 10px 0 0;
        font-size: $body-font-size;
        font-weight: $sm-body-font-weight;
        width: 75px;
    }
    &__sort-by-dropdown-select {
        font-size: $body-font-size;
        font-weight: $sm-body-font-weight;
    }

    &__product {
        background: $background;
        border: 1px solid $ph-medium-grey;
        margin: 10px 0;

        &:first-child {
            border-top: 1px solid $ph-medium-grey;
        }
        
        &:hover {
            border: 1px solid $ph-gold;
            transition: all .3s ease-in-out;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__left {
        padding:.25rem;
        display:flex;
        // justify-content: center;
        align-items: center;
        width: 80%;
        position: relative;
        overflow: hidden;

        &--img-container {
            padding:.5rem;
            cursor: pointer;
            width: 10%;
        }

        &--container {
            margin: 0 .125rem 0 .5rem;
            width: 90%;
        }

        &--title {
            font-size: $h2;
            padding: 0.25rem .125rem .25rem .5rem;
            font-weight: 400;
            color: $ph-dark-grey;
            cursor: pointer;
            transition: .3s ease;
            margin:0 !important;
            display:block;
            overflow: hidden;
        }

        &--short-desc {
            margin: 0;
            padding: 0 .125rem 0 .5rem;
        }

        &--links {
            padding: 0 .125rem 0 1rem;
        }
    }

    &__right {
        border-left:1px solid $ph-medium-grey;
        display:flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        flex-direction: column;
        cursor: pointer;

        &--part-quantity {
            font-size: $h2;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }

        &--btn {
            margin: 0;
            padding: 0;
        }

    }

    &__details {
        // display: none;
        // padding: 1rem 1.5rem 2rem 1.5rem;
        color: $ph-dark-grey;
        line-height: 1.6;
        border-top:1px solid $ph-medium-grey;

        // &--open {
        //     display: block;
        // }
    }
  }


  @media screen and (max-width: 960px){
    .ph-browse-list-view__left--img-container {
        width:25%;
    }  
    .ph-browse-list-view__left--container {
        width: 75%;
    }
    .ph-browse-list-view {
        &__left--title {
            font-size: $body-font-size;
        }

        &__right {
            text-align: center;

        &--part-quantity {
            font-size: $body-font-size;
            line-height: 1.25;
        }

        & i {
            margin:0;
        }

        }
    }
  }

.dt-more-container {
    text-align:center;
    margin:2em 0;
 }