/* Cards
  =============== */


.ph-card__wrapper {
  flex-shrink: 0;
  // display: inline-block;
}

.ph-card-basic__link {
  background-color: $background;
  border: solid 2px $ph-medium-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 140px;
  padding: rem-calc(16 12);
  position: relative;
  text-align: center;
  width: auto;

  @media #{$small-up} {
    width: 100%;
  }

  @media #{$medium-up} {
    font-size: 1.125rem;
    line-height: 1.375;
    padding: rem-calc(16 20);
  }

  @media #{$large-up} {
    padding: rem-calc(20 27);
  }

  @media #{$xxlarge-up} {
    padding: rem-calc(27 32);
  }

  &:hover, .active {
    border-color: $ph-gold;
    text-decoration: none;
  }

  p {
    font-size: $font-size;

    @media #{$large-up} {
      font-size: 1.125rem;
      margin-bottom: rem-calc(20);
    }
  }

  span {
    clear: both;
    display: block;
    font-size: .75rem;
    line-height: 1.25;
    margin-top: 1rem;
    text-align: center;
    width: 100%;

    @media #{$xlarge-up} {
      line-height: 1.5;
    }
  }

  h2 {
    font-size: $h3;
    margin: rem-calc(8 0 16 0);

    @media #{$large-up} {
      font-size: $h2;
      margin: rem-calc(20 0);
    }
  }

  & + p {
    font-size: rem-calc(15);
    margin-top: .5rem;
    padding: 1rem;
    text-align: center;
  }

  & + ul {
    margin-top: 1.5rem;
  }

  //remove the bulk from the basic link cards to thin them out
  &.thin {
    border: solid 2px $ph-light-grey;
    min-height: auto !important;
    padding: 0;

    &:hover {
      border-color: $ph-gold;
    }

    & h2 {
      margin: rem-calc(8 0);
    }
  }

  &.highlight {
    background-color: $highlight;
  }

  .fa-external-link-alt {
    color: $ph-grey;
    font-size: rem-calc(21);
    position: absolute;
    right: 5px;
    top: 5px;

    @media #{$large-up} {
      font-size: rem-calc(27);
      right: 8px;
      top: 8px;
    }
  }
}

// Use an article element to maintain usability for screen readers.

// Card deck slider

.ph-card-deck {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  
  // &::-webkit-scrollbar {
  //   width: 6px;
  // height: 6px;
  // background-color: #F5F5F5;
  // overflow-x: auto;
  // }

  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  //   border-radius: 10px;
  //   background-color: #F5F5F5;
  //   overflow-x: auto;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  //   background-color: #3c8dbc;
  //   overflow-x: auto;
  // }

  // &:after {
  //   z-index: 3;
  //   font-size: 2rem;
  //   font-family: $icon-font-family;
  //   font-weight: $icon-font-weight;
  //   content: '\f054';
  //   cursor: pointer;
  //   color: $ph-dark-grey;
  //   position: absolute;
  //   right:5px;
  //   top:30%;
  //   padding:2em 0;
  //   background-image:
  //   linear-gradient(
  //     to right, 
  //     rgba(255,255,255,0), rgba(255,255,255,1),
  //   );
  //   width: 100px;
  //   // height: 85%;
  //   text-align: right;

  //   &:hover {
  //     color: $ph-gold;
  //   }
  // }

  &__scrollable {
    display: inline-block;
    margin:0 .5rem;
    vertical-align: top;
    
    &:first-child {
      margin-left:0;
    }
    
  }
}

// Basic Cards
.ph-card-basic {
    position: relative;
    padding: 0;
    background: #fff;
    border: 2px solid $ph-medium-grey;
    margin: .75rem 0;
    text-align: center;
    width: 100%;
    // max-width: 350px;

    &__header {
        padding: .5rem 1rem 0;
        margin: 0 0 .75rem;
    }

    &__image {
      width: 100%;
      flex: 0 0 auto;
      img {
          width: 75%;
          max-width:175px;
          height: auto;
        
      }
   }

    &__title {
      font-size: 1.125rem;
      line-height: 1.25;
    }

    &__body {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 0 1rem;
    }

    &__footer {
        padding: .5rem 1rem;
        margin-top: .25rem;
        margin-bottom: .75rem;
        font-size: $body-font-size;
    }
}

// Product Cards - Vertical
.ph-card-vertical {
  position: relative;
  padding: 0;
  background: #fff;
  // border: 2px solid transparent;
  margin: 1rem;
  width: 100%;
  max-width: 215px;
  min-width: 175px;
  height:auto;
  white-space: normal;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.025);
    // border: 2px solid #E2E1DD;
    // box-shadow: 0 8px 10px -6px $ph-dark-grey;
  }

  &__header {
      padding:0;
      margin: 0;
  }

  &__image {
     width: 100%;
     flex: 0 0 auto;
     img {
         width: 75%;
         height: auto;
         max-height: 215px;
     }
  }

  &__title {
    padding: 0 0rem;
    font-size: $body-font-size;
    font-weight: 700;
    line-height: 1.25;
  }

  &__number {
    font-size: $body-font-size;
    font-weight: $font-weight;
  }

  &__body {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 0 1rem;
  }

  &__footer {
      padding: .5rem 1rem;
      margin-top: .25rem;
      margin-bottom: .75rem;
      text-align: left;
      font-size: .8125rem;
  }
}

//Figure element, to emphasize an image with a large caption overlay
.ph-card-figure {
  margin: rem-calc(0 0 16 0);
  position: relative;

  img {
    border: solid 2px $ph-light-grey;
  }

  figcaption {
    font-size: rem-calc(18);
    position: absolute;
    padding: .75rem;
    left: 0;
    top: 0;

    @media #{$medium-up} {
      font-size: rem-calc(21);
    }

    @media #{$large-up} {
      font-size: rem-calc(24);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(27);
    }
  }
}

// Product Card 1
.ph-card-1 {
  position: relative;
  background: #fff;
  border: solid 2px $ph-medium-grey;
  margin: 1rem 0;
  width: 100%;
  height: auto;
  white-space: normal;
  transition: all .2s ease-in-out;
  text-align: center;


  &:hover {
    // transform: scale(1.025);
    border: 2px solid $ph-gold;
  }

  & a:hover {
    color:$ph-dark-grey;
    text-decoration: none;
  }

  &__left {
    width:100%;
    height:150px;
    float:none;
    overflow:hidden;
    background:transparent;
    padding: 22px;
    position: relative;

    img {
      width:100px;
      height:auto;
      position:relative;
    }
 }

  &__right {
    width:100%;
    float:none;
    height:150px;
    padding-top:30px;
    padding-left:10px;
    padding-right:10px;
    position: relative;

    p {
      margin-bottom:.5rem;
    }
}

  &__title {
    font-size: $body-font-size;
    font-weight: 400;
    line-height: 1.25;
  }

  &__action {
    padding-top: 15px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }


  &__number {
    display: inline-block;
  }

}

@media only screen and (min-width:400px) {
.ph-card-1 {
  width: 450px;
  max-width: 100%;
  height: 150px;
  text-align: left;

  &__left {
    width:28%;
    height:150px;
    float:left;
 }

  &__right {
    width:72%;
    float:left;
    height:150px;
}

  &__action {
    padding-top:15px;
    padding-bottom: 20px;
    padding-left:10px;
    position: absolute;
    bottom: 0;
  }

}
}




// Marketing Cards

//Horizontal
.ph-card-mktg-1 {
  background-color: $background;
  border: solid 2px $ph-medium-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 140px;
  // padding: rem-calc(16 12);
  text-align: center;
  width: auto;
  transition: all .2s ease-in-out;


  @media #{$small-up} {
    width: 100%;
  }

  @media #{$medium-up} {
    font-size: 1.125rem;
    line-height: 1.375;
    // padding: rem-calc(16 20);
  }

  @media #{$large-up} {
    min-height: 170px;
    // padding: rem-calc(20 27);
  }

  @media #{$xxlarge-up} {
    // padding: rem-calc(20 72);
    flex-direction: row;
  }

  &:hover, .active {
    border-color: $ph-gold;
    text-decoration: none;
  }

  &__content {
    width: 65%;
    padding: rem-calc(16 12);
  }

  &__title {
    font-size: $font-size;

    @media #{$large-up} {
      font-size: 1rem;
      margin-bottom: rem-calc(20);
    }
  }

  &__subtitle {
    font-size: .875rem;
  }
  
  &__img-container {
    margin:auto;
    padding: rem-calc(16 12);

    img {
      width: 100px;
      height: 100px;
  }
}

@media #{$xxlarge-up} {
  &__divider {
    border: solid 1px $ph-medium-grey; 
    min-height: 170px;
    height: 100%;
  }
}


  // a {
  //   display: block;

  //   &:hover {
  //     text-decoration: none;
  //     color:$ph-dark-grey;
  //   }
  // }

  // &:hover {
  //   border: 2px solid $ph-gold;
  // }

//   &__left {
//     // width:32%;
//     // height:146px;
//     // float:left;
//     // overflow:hidden;
//     // background:$background;
//     // padding: 20px 16px;
//     // position: relative;
//     // border-right: 2px solid $ph-light-grey;

//     // img {
//     //   width:100px;
//     //   height:auto;
//     //   position:relative;
//     // }
//  }

//   &__right {
//     width:68%;
//     float:left;
//     height:146px;
//     padding-top:55px;
//     padding-left:10px;
//     padding-right:10px;
//     position: relative;
//     background-color: $ph-lighter-grey;

// }

  // &__content {
  //   font-size: $body-font-size;
  //   font-weight: 700;
  //   line-height: 1.25;
  //   margin-bottom:0;
  // }

}

// Vertical
.ph-card-mktg-2 {
  position: relative;
  padding: 0;
  background: #fff;
  border: 2px solid $ph-medium-grey;
  width: 100%;
  min-width: 175px;
  height:auto;
  white-space: normal;
  transition: all .2s ease-in-out;

  a {
    display: block;
    &:hover {
      text-decoration: none;
      color:$ph-dark-grey;
    }
  }

  &:hover {
    border: 2px solid $ph-gold;
  }

  &__header {
      padding:0;
      margin: 0;
  }

  &__image {
     width: 100%;
     flex: 0 0 auto;
     text-align: center;
     img {
         max-width: 200px;
         height: auto;
         max-height: 215px;
     }
  }

  &__footer {
      padding: .5rem 1rem;
      margin-top: .25rem;
      text-align: center;
      background-color: $ph-light-grey;
  }

  &__title {
    font-size: $body-font-size;
    font-weight: 700;
    line-height: 1.25;
    padding-top:1.25rem; 
  }

}


// Blog/Article/Community Cards - Vertical

.ph-card-community {
  position: relative;
  padding: 0;
  background: #fff;
  border: 2px solid #E2E1DD;
  // margin: 1rem;
  width: 100%;
  max-width: 450px;
  min-width: 175px;
  height:auto;
  white-space: normal;
  transition: all .2s ease-in-out;

  &:first-child {
    margin-left: 0;
  }

  a {
    display: block;

    &:hover {
      text-decoration: none;
      color:$ph-dark-grey;
    }
  }

  &:hover {
    border: 2px solid $ph-gold;
  }

  &__header {
      padding:0;
      margin: 0;
  }

  &__image {
     width: 100%;
     flex: 0 0 auto;
     img {
         width: 100%;
         height: auto;
        //  max-height: 215px;
     }
  }

  &__title {
    padding: 0 1rem;
    font-size: $h4;
    font-weight: 500;
    line-height: 1.25;
  }

  &__body {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 0 1rem;
      text-transform: capitalize;
  }
}







