/* Forms
  =============== */

  .ph-form { 
    padding:0 .75rem;
}  

// Display for all input fields (except buttons and checkbox) 
%forms {
    display: block;
    border: $form-border;
    border-radius: $ph-medium-grey;
    padding: .5rem .75rem;
    outline: none;
    background: $input-background;
    margin-bottom: .5rem;
    font-size: .875rem;
    width: 100%;
    max-width: 100%;
    line-height: 1;
}

// Input fields on hover
%forms-hover {
    border: $form-border-hover;
}

// Input fields on focus 
%forms-focus {
    border: $form-border-focus;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), 0 0 6px lighten($link-color, 30%);
}

// Variable containing all input fields 
#{$forms} {
    @extend %forms;
    &:hover {
        @extend %forms-hover;
    }
    &:focus,
    &:active {
        @extend %forms-focus;
    }
}

textarea {
    overflow: auto;
    height: auto;
}

fieldset {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: $padding;
    margin: $margins 0;
}

legend {
    padding: 0 .5rem;
    font-weight: 600;
}

// Fix issues with select
select {
    color: $font-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../img/ph-arrow-down.png) no-repeat;
    background-position: right 5px center;
    line-height: 1; // ensures text doesn't get cut off
    padding: rem-calc(10 25 10 10);

    &:focus {
        background-image: url(../img/ph-arrow-up.png);
    }
}

//PH Sort select box 
.ph-sort-dropdown {
    border-radius: 25px;
    border:2px solid $ph-gold;
    padding:.65rem 1.5rem;

    &:hover, 
    &:active,
    &:visited,
    &:focus  {
        border:2px solid $ph-gold;
    }
}

select::-ms-expand {
    display: none; // dropdown icon fix for IE
}

// Make range full width
[type=range] {
    width: 100%;
}

// Labels
label {
    font-weight: 600;
    max-width: 100%;
    display: block;
    margin: 1rem 0 .5rem;
}

@include small-breakpoint {
    .split-form {
        // Split forms have right aligned labels and margins on the column
        label {
            text-align: right;
            padding: 0 .5rem;
            margin-bottom: 1rem;
        }
    }
}

// Errors
input,
select,
textarea {
    &.has-error {
        border: 1px solid $error;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), 0 0 6px lighten($error, 35%);
    }
    &:hover,
    &:focus,
    &:active {
        &.has-error {
            @extend .has-error;
        }
    }
}

.has-error {
    color: $error;
    i {
        color: $error;
    }
    &:hover,
    &:focus,
    &:active {
        color: $error;
        text-decoration: underline;
    }
}

// Placeholder Text 
::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: $placeholder;
}


.qty-box {
    width: 100% !important;
    max-width: 100px !important;
    min-width: 100px !important;
}

// Form Types

.ph-form-row {
    // display: flex;
    // justify-content: flex-end;
    padding: .5rem;
    width: 100%;

    &__label {
        padding: 0;
        margin:0 0 .25rem 0;
        // flex: 1;

        .required {
            color: $error;
        }
    }

    &__input {
        // flex: 2;
        border-radius: 0;
        border-color: $ph-dark-grey;
        min-height: 45px;
    }
    
    &__textarea {
        // flex: 2;
        border-radius: 0;
        border-color: $ph-dark-grey;
    }

    &__group {
        display: block;

        &--inline {
            display: inline-block;
        }
    }

    &__button {
        padding: .5em;
        background: gray;
        color: white;
        border: 0;
    }
  }

  .ph-input {
    display: block;
    font-weight: 400;

    &__inline {
        display: inline-block;
        margin-right:1rem;
    }

    &__radio {
    }

    &__label {

        &--inline {
            display: inline-block;
            margin:0 0 0 .25rem;
            font-weight: 400;
        }
    }

    &__fileUpload {
  
        cursor: pointer !important;
        
        &::-webkit-file-upload-button {
          font-size: .75rem;
          font-weight: 500;
          padding: .25rem 1.25rem;
          margin: 0;
          text-transform: uppercase;
          margin-top: 20px;
          background: $ph-gold;
          border: 0;
          cursor: pointer;
          color: $ph-dark-grey;
          border-radius: 2rem;
        }
        
        &::-ms-browse {
          font-size: .75rem;
          font-weight: 500;
          padding: .25rem 1.25rem;
          margin: 0;
          text-transform: uppercase;
          margin-top: 20px;
          background: $ph-gold;
          border: 0;
          cursor: pointer;
          color: $ph-dark-grey;
          border-radius: 2rem;
        }
        
      }
  }


  @media only screen and (max-width:480px) {
    .ph-input__inline {
        display: block;
    }
}

// Basic Search bar
.search {
    width: 100%;
    position: relative;
    display: flex;
    border:1px solid #424242;
  }
  
  .searchTerm {
    display: block;
    padding: 0 1rem 0 1rem;
    outline: none;
    background: transparent;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    line-height: 1;
    border: none;
  }
  
  .searchTerm:focus{
    color: #424242;
    border-radius: 0;
  }
  .searchTerm:hover{
    color: #424242;
    border-radius: 0;
    border:none;
  }
  
  .searchButton {
    width: 100px;
    height: 45px;
    // border: 1px solid #ffb91d;
    background: #ffb91d;
    text-align: center;
    color: #424242;
    border-radius: 0;
    // border:1px solid #424242;
    border-left:none;
    cursor: pointer;
    font-size: .875rem;
    margin-left: -3px;
    font-family: Roboto,Arial,sans-serif;
    padding-top: 10px;
    font-weight: 500;
  }

.ph-search-input {
    position: relative;

    input {
        background-color: $white;
        padding-right: 35px;
    }

    button.submit {
        border-radius: 0;
        margin: 0;
        min-width: unset;
        padding: 0;
        position: absolute;
        right: 1px;
        top: 1px;

        i.fas {
            color: $white;
            font-size: rem-calc(23);
            padding: 3px;
        }
    }
}

//Set sidebar fields to use a white background
.ph-sidebar {
    input, select, textarea {
        background-color: $white;
    }
}
  
//   /*Resize the wrap to see the search bar change!*/
//   .wrap{
//     width: 30%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

// .show-proceed {
//     display: none;
// }