/* Modals
  =============== */

 $modal-header-font-size: $h2;
 $modal-footer-font-size:1rem;
 $modal-body-font-size: $body-font-size;


/* The Modal (background) */
.ph-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  &__content {
    position: relative;
    background-color: $background;
    margin: auto;
    padding: 0 3.1875rem 0 3.1875rem;
    border: 1px solid $ph-dark-grey;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  &--email {
    width:30%;
  }

  &--video {
    width: 100%;
    max-width: 1000px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: $background;
    color: $ph-dark-grey;
    
    h3 {
      font-weight: 400;
      text-align: left;
      margin: 0;
      // align-self: center;
      // flex-grow: 1;
    }

    p {
      text-align: left;
      font-size: $body-font-size;
    }

    &--close {
      color: $ph-dark-grey;
      font-size: 24px;
      font-weight: bold;
      padding: 1rem 0 0 0;

      &:hover, 
      &:focus {
        color: $ph-gold;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &__body {
    padding: 20px 0;

    video {
      width:100%;
    }
  }

  &__footer {
    padding: 1rem 16px 3.1875rem 16px;
    background-color: $background;
    color: $ph-dark-grey;
    text-align: center;

    button {
      margin: .25rem .75rem;
    }

    h3 {
      text-align: center;
    }
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

// /* The Close Button */
// .close {
//   color: white;
//   float: right;
//   font-size: 28px;
//   font-weight: bold;
// }

// .close:hover,
// .close:focus {
//   color: #000;
//   text-decoration: none;
//   cursor: pointer;
// }


@media screen and (max-width: 768px){
  .ph-modal {
    &__content {
      width: 95%;
    }
  }
}

// WebSphere Speific styles

.emailErrorMsg {
  font-weight: 400;
    color: #D11515;
    font-size: 12px;
    margin-top: 0;
}

/*
* Popover Styling
* @see https://getbootstrap.com/docs/4.3/components/popovers/#example-using-the-container-option
*
*/
a[data-toggle="popover"] {
  font-size: rem-calc(17);
  text-decoration: underline;
  text-decoration-color: $ph-dark-grey;

  &:hover {
    text-decoration-color: $ph-gold;
  }
}

.popover {
  background-color: $background;
  //border: solid 2px $ph-light-grey;
  margin: 0 auto;
  max-width: rem-calc(730);
  min-width: rem-calc(300);
  padding: .375rem .75rem .5rem .75rem;

  @media #{$medium-up} {
    padding: .375rem 1.375rem 1rem 1.375rem;
  }

  &:before {
    background-color: $background;
    border: solid 2px;
    border-right-color: $ph-light-grey;
    border-top-color: $ph-light-grey;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-bottom-width: 2px;
    display: block;
    content: " ";
    margin-right: -20px;
    position: absolute;
    height: 42px;
    width: 40px;
    line-height: 1;
    right: 50%;
    -ms-transform: rotate(-36deg) skewY(-18deg); /* IE 9 */
    transform: rotate(-36deg) skewY(-18deg);
  }

  &.bs-popover-top {
    box-shadow: 1px -1px 7px rgba(0,0,0,.2);
    margin-bottom: 40px;

    &:before {
      border-right-color: transparent;
      border-top-color: transparent;
      border-left-color: $ph-light-grey;
      border-bottom-color: $ph-light-grey;
      bottom: -20px;
    }
  }

  &.bs-popover-bottom {
    box-shadow: 1px 1px 7px rgba(0,0,0,.2);
    margin-top: 40px;

    &:before {
      border-right-color: $ph-light-grey;
      border-top-color: $ph-light-grey;
      border-left-color: transparent;
      border-bottom-color: transparent;
      top: -20px;
    }
}

  .popover-content {
    .ph-close {
      float: right;

      @media #{$medium-up} {
        margin-right: -1rem;
      }
    }
  }
}
