/* Alerts
  =============== */


.ph-flag {
    background-color: $ph-alert-blue;
    padding:.25rem 1rem;
    font-size: $body-font-size;
    color: white;
    margin:.5rem .5rem;
    border-radius: 0;

    &__obsolete {
        background-color: $error;
        color: #ffffff;
        // text-transform: uppercase;
    }

    &__status {
      // Specific to RGA
      &--active {
        background-color: $success;
      }
      &--denied {
        background-color: $error;
      }
      &--submitted {
        background-color: $informational;
      }
    }
  }

.ph-alert {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  background-color: $ph-alert-blue;
  padding:1rem 1rem;
  font-size: $body-font-size;
  color: white;
  text-align: center;
  z-index:1000;

  p {
    width: 100%;
  }

  a {
    color: #fff;
    font-weight: 400;
    padding: .15rem .5rem;
    border: 1px solid white;
    border-radius: 50px;
    margin-left:20px;

    &:hover {
      text-decoration: none;
      background:#fff;
      color:$ph-dark-grey;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: center;
  }

  &__success {
    background-color: $success;
    display: none;
  }
  &__warning {
    background-color: $warning;
    display: none;
  }
  &__informational {
    background-color: $informational;
    display: none;
  }
  &__close {
    color:#fff;
    // float:right;
    padding: 0 .5rem;
    // margin: -7px 1px;
    background: none;
    text-align: right;
    border: none;
    font-size: 2rem;
    min-width: auto;
    cursor: pointer;
    
    &:hover {
      text-align: right;
      border: none;
      background: none;
      outline: none;
    }

    &:focus,
    &:active {
      border: none;
      background: none;
      outline: none;
      color:#fff;
    }
  }
}

// #success-alert {
//   display:none;
// }



//Gold text for emphasis
.ph-gold {
  color: $ph-gold !important;
}

//Red text for warnings
.ph-red {
  color: $warning !important;
}

.show-success {
  background-color: $success;
  color: #fff;
  border: 2px solid $success;
}
.show-warning {
  background-color: $warning;
  color: #fff;
  border: 2px solid $warning;
}
.show-informational {
  background-color: $informational;
  color: #fff;
  border: 2px solid $informational;
}


// PROP65 Warning
.ph-warning {
  padding: 1rem 1rem;

  i {
    color: #424242;
    font-size: 17px;
  }
}

.prop-65-img {
  max-width: 20px;
  width: 100%;
  margin-right:5px;
}