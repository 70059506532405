/* Carousels
  =============== */


/* CSS for the loading div */

.ph-product-img-loading {
	text-align: center;
	max-width: 270px;
	padding: 15px;
	border: 5px solid #eee;
	border-radius: 3px;
	font-size: 12px;
	color: #888;
}

/* Element wrapper */

.ph-product-img-wrap {
	// display: none;
    line-height: 0;
    font-size: 0;
    background: #fff;
    position: relative;
    margin: 15px auto;
    padding: .25rem .5rem;
    text-align: center;
	/**************
	  Set max-width to your thumbnail width
	***************/
  	max-width: 250px;
}



/* Thumbnails */

.ph-product-img-thumbs {
  text-align: center;
  display: inline-block;
  margin: .25rem auto .25rem;
  padding: .5rem 0;
}

.ph-product-img-thumbs img {
	min-height: 50px;
	min-width: 50px;
	max-width: 50px;
}
.ph-product-img-thumbs a:link, .ph-product-img-thumbs a:visited {
	width: 50px;
	height: 50px;
	margin:5px 5px;
	overflow: hidden;
	opacity: .7;
	display: inline-block;
	background-size: cover;
	background-position: center;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.ph-product-img-thumbs a:hover {
	opacity: 1;
}

/* Styles for the currently selected thumbnail */

.ph-product-img-thumbs a:active, .ph-product-img-current {
	opacity: 1!important;
  position: relative;

}

/* Image currently being viewed */

.ph-product-img-large {
	position: relative;
	/* overflow: hidden; */
	/* top: 0;
	left: 0; */
	margin: 0 auto;
}
.ph-product-img-large a img {
	width:100%;
	max-width: 160px;
	height: auto;
	margin:5px auto;
  display:block;
  border:2px solid transparent;
  
  &:hover {
    border:2px solid $ph-gold;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
}
// .ph-product-img-large a {
// 	display: block;
// }

/* Panning Zoomed Image */

/* .ph-product-img-zoom {
	position: absolute;
	left: -50%;
	top: -50%;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
	display: none;
} */
/* Lightbox */

.ph-product-img-lightbox {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, .9);
	z-index: 9000000000;
	display: none;
	cursor: pointer;

  & img {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 90%;
    max-height: 90%;
    border: 2px solid #fff;
  }

}

#ph-product-img-prev, #ph-product-img-next {
	position: absolute;
	top: 50%;
	margin-top: -25px;
	z-index: 501;
	color: #fff;
	padding: 14px;
	text-decoration: none;
	background: #000;
	border-radius: 25px;
	border: 2px solid #fff;
	width: 50px;
	height: 50px;
	box-sizing: border-box;
	transition: .2s;
}
#ph-product-img-prev {
	left: 10px;
}
#ph-product-img-prev:before {
  content: '\f053';
  font-family: $icon-font-family;
  font-weight: 900;
  font-size: 1.25rem;
	position: absolute;
	top: 10px;
	left: 15px;
}
#ph-product-img-next {
	right: 10px;
}
#ph-product-img-next:before {
  content: '\f054';
  font-family: $icon-font-family;
  font-weight: 900;
  font-size: 1.25rem;
	position: absolute;
	top: 10px;
	left: 18px;
}
#ph-product-img-prev:hover, #ph-product-img-next:hover {
	background: #444;
}

/* Tweak styles for small viewports */

@media screen and (max-width: 400px) {
	.ph-product-img-wrap {
		margin: 0 0 15px 0;
	}
	#ph-product-img-prev, #ph-product-img-next {
		top: auto;
		margin-top: 0;
		bottom: 25px;
	}
}

.ph-product-img-icon {
  width:100%;
  text-align: right;
  padding: .25rem 0 .25rem 0;
  border-bottom: 1px solid #c5c5c5;

  & i {
    font-size: 1rem;
    color: #c5c5c5;
    text-align: right;
  }
}


