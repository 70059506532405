/* Variables
 * ===============
 *
 * All variables and most of the configuration is defined on this page. */

 $rem-base: 16px !default;

 // We use this to prevent styles from being loaded multiple times for components that rely on other components.
 $modules: () !default;
 // We use these to control text direction settings
 $text-direction: ltr !default;
 $default-float: left !default;
 $opposite-direction: right !default;
 @if $text-direction == ltr {
   $default-float: left;
   $opposite-direction: right;
 } @else {
   $default-float: right;
   $opposite-direction: left;
 }

$column-gutter: rem-calc(30) !default;
/* Containers */
@mixin exports($name) {
    // Import from global scope
    $modules: $modules !global;
    // Check if a module is already on the list
    $module_index: index($modules, $name);
    @if (($module_index == null) or ($module_index == false)) {
        $modules: append($modules, $name) !global;
        @content;
    }
}

// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
    @if length($range) <= 0 {
        @return 0;
    }
    @return nth($range, 1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

// It strips the unit of measure and returns it
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

// CONVERT PX TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;
    @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);

    @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }
    @return $remValues;
}

@function em-calc($values, $base-value: $rem-base) {
    $remValues: rem-calc($values, $base-value: $rem-base);

    $max: length($remValues);

    @if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

    $emValues: ();
    @for $i from 1 through $max {
        $emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
    }
    @return $emValues;
}

/* Breakpoints */
$tiny: 359px;
$x-small: 600px;
$small: 767px;
$medium: 1025px;
$large: 1220px;

$tiny-breakpoint: em-calc(359) !default;
$small-breakpoint:  em-calc(600)  !default;
$medium-breakpoint: em-calc(767) !default;
$large-breakpoint:  em-calc(1025) !default;
$xlarge-breakpoint: em-calc(1220) !default;
$xxlarge-breakpoint: em-calc(1440) !default;

$small-range:   ($tiny-breakpoint, $small-breakpoint) !default;
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint)  !default;
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xxlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$tiny-up: $screen;
$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})" !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$mobile: $x-small;
$tablet: $small;
$desktop: $medium;
$xl: $large;

/* Colors */

// Brand Colors
$white : #ffffff;
$ph-gold: #ffb91d;
$black: #070C1A;
$ph-dark-grey: #424242;
$ph-grey: #888787;
$ph-warm-grey: #BCBCBC;
$ph-medium-grey: #ccd2d7;
$ph-light-grey: #F0F0EF;
$ph-lighter-grey: #FBFBFB;

$ph-alert-blue: #0076CC;
$ph-alert-gold: #ffb91d;

$background: #ffffff; 
$alternate-background: #fafafa;
$alternate-color: #404040;
$link-color: #222222;
$link-hover-color: darken($link-color, 15%);
$highlight: #ffeea8;
$error: #D11515;
$success: #0AC410;
$warning: #D11515;
$informational: #1751C6;
$bq-border: 16px solid #f0f0f0;

/* Typography */

// Default Body Font Styles
$font-size: 1rem; // 16px
$body-font-size: 0.875rem; // 14px
$font-style: normal;
$font-variant: normal;
$font-weight: 400;
$font-color: $ph-dark-grey;
$font-family: 'Roboto',Arial,sans-serif;
$line-height: 1.5;

// Small Body Font Styles
$sm-body-font-size: 0.75rem; /* 12px/16px = 0.75rem */
$sm-body-font-style: normal;
$sm-body-font-variant: normal;
$sm-body-font-weight: 400;
$sm-body-font-color: $ph-dark-grey;
$sm-body-font-family: 'Roboto',Arial,sans-serif;
$sm-body-line-height: 1.2;

// Headings
$heading-font-color: #404040;
$heading-font-weight: 400;
$heading-font-family: 'Roboto',Arial,sans-serif;
$heading-line-height: 1.2;

// Mobile heading font size
$h1-mobile: 1.5625rem; /* 25px/16px = 1.5625rem */ 
$h2-mobile: 1.25rem; /* 20px/16px = 1.25rem */
$h3-mobile: 1rem; /* 16px/16px=1rem */
$h4-mobile: 1.125rem; /* 18px/16px = 1.125rem */
$h5-mobile: 0.875rem; /* 14px/16px = 0.875rem */
$h6-mobile: 0.75rem; /* 12px/16px = 0.75rem */

// Heading font size
$h1: 1.875rem; /* 30px/16px = 1.875rem */ 
$h2: 1.5rem; /* 24px/16px = 1.5rem */
$h3: 1.25rem; /* 20px/16px = 1.25rem */
$h4: 1.125rem; /* 18px/16px = 1.125rem */
$h5: 0.875rem; /* 14px/16px = 0.875rem */
$h6: 0.75rem; /* 12px/16px = 0.75rem */

// Font weights 
$font-weight-light: 300;
$font-weight-regular:400;
$font-weight-medium:500;
$font-weight-bold:700;
$font-weight-extra-bold:900;

// Links
$link: $ph-dark-grey;
$visit: $ph-dark-grey;
$hover: $link-color;
$active: $link-color;

// Icon font
$icon-font-family: "Font Awesome 5 Pro";
$icon-font-weight: 900;

/* Padding */

$padding: 1rem;
$margins: 1.5rem;

/* Borders */

$border-width: 1px;
$border-style: solid;
$border-color: #dedede;
$border-radius: 0;
$borders: $border-width $border-style $border-color;

/* Buttons */

$button-background: $ph-gold;
$button-background-hover: darken($button-background, 10%);
$button-color: $ph-dark-grey;
$button-font-weight: 500;
$button-font-family:  "Roboto", Arial, sans-serif;
$button-font-size: 1rem;
$button-border-width: 2px;
$button-border-style: solid;
$button-border-color: $button-background;
$button-border-radius: $border-radius;
$button-text-transform: none;

// Accent buttons
$accent-button-background: $background;
$accent-button-color: $ph-dark-grey;
$accent-button-color-hover: $ph-dark-grey;
 
// Muted Buttons
$muted-border: 1px solid $ph-light-grey;
$muted-border-hover: 1px solid darken($ph-light-grey, 30%);
$muted-background: #C5C5C5;
$muted-background-hover: #C5C5C5;
$muted-color: darken($ph-light-grey, 50%);
$muted-color-hover: darken($ph-light-grey, 50%);

// Round Buttons   
$round-buttons: 40px; 

/* Forms */

$forms: ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$buttons: ('.button, a.button, button, [type=submit], [type=reset], [type=button]');
$input-background: transparent;
$placeholder: darken($ph-light-grey, 20%);
$form-border: 1px solid $border-color;
$form-border-hover: 1px solid darken($border-color, 10%);
$form-border-focus: 1px solid $link-color; 
 
/* Tables */

$stripes: #f8f8f8;
$caption: #ababab;

/* Code */

$code-color: $font-color;
$code-size: 14px;
$code-family: Menlo, monospace;
$code-background: transparent;
$code-borders: $borders; 

