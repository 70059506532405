body.noScroll { /* Or body.dialogShowing */
  overflow: hidden;
}

/*Set the row height to the viewport*/
.row-height{
  height: 80vh;
}

/*Set a max width on a row to avoid nesting columns*/
.max {
  &-1140 {
    max-width: 1140px
  }

  &-900 {
    max-width: 900px
  }

  &-800 {
    max-width: 800px
  }

  &-700 {
    max-width: 700px
  }

  &-600 {
    max-width: 600px
  }

  &-500 {
    max-width: 600px
  }
}

.min-full {
  width: 100%;
}

/*Set up the columns with a 100% height, body color and overflow scroll*/

.lefty{
  height: 100%;
  overflow-y: scroll;
}

/* .right{
  background-color: blue;
  height: 100%;
  overflow-y: scroll;
} */

.mid{
  height: 100%;
  overflow-y: scroll;
}

//Gratutiously padded blocks
.py-extra {
  padding-bottom: rem-calc(40);
  padding-top: rem-calc(40);

  @media #{$large-up} {
    padding-bottom: rem-calc(55);
    padding-top: rem-calc(55);
  }

  @media #{$xlarge-up} {
    padding-bottom: rem-calc(70);
    padding-top: rem-calc(70);
  }
}

.px-extra {
  padding-left: rem-calc(40);
  padding-right: rem-calc(40);

  @media #{$xlarge-up} {
    padding-left: rem-calc(55);
    padding-right: rem-calc(55);
  }

  @media #{$xxlarge-up} {
    padding-left: rem-calc(70);
    padding-right: rem-calc(70);
  }

  &-xxl {
    padding-left: 0;
    padding-right: 0;
  
    @media #{$xxlarge-up} {
      padding-left: rem-calc(80);
      padding-right: rem-calc(80);
    }
  }
}

// /*Remove the scrollbar from Chrome, Safari, Edge and IE*/
// ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
// }

// * {
//   -ms-overflow-style: none !important;
// }

// Main Sections of Content 
// @use <section>
section.ph-content-section {
  padding-bottom: 1rem;
  padding-top: 1rem;

  @include medium-breakpoint {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
  
  @include large-breakpoint {
    padding-bottom: 3rem;
  }

  hr {
    border-top: solid 3px $ph-lighter-grey;
    margin: 0 0 2rem 0;

    &.inset {
      margin-bottom: 0;
    }
  }

  @include medium-breakpoint {
    hr {
      margin: 0 0 3rem 0;
    }
  }

  @include large-breakpoint {
    hr {
      margin: 0 0 4.5rem 0;
    }
  }

  .ph-content-section {
    &__topic {
      @include large-breakpoint {
        padding-bottom: 3rem;
      }

      h1 {
        margin-bottom: 2rem;

        @include large-breakpoint {
          margin-bottom: 3rem;
        }
      }

      &.col-12 {
        h1 {
          @include large-breakpoint {
            text-align: center;
          }
        }
      }

      p {
        margin-bottom: .875rem;

        @include medium-breakpoint {
          width: 90%;
        }
      }

      ul {
        padding: 1rem 0 1rem 1.25rem;

        li {
          font-size: .875rem;
          margin-bottom: .375rem;
          padding-left: .25rem;
        }
      }
    }

    &__info {

      h6 {
        border-bottom: solid 1px $ph-medium-grey;
        font-size: .75rem;
        font-weight: 600;
        margin: 1.5rem 0;
        padding: .5rem 0;

        @include large-breakpoint {
          border-bottom: none;
          border-top: solid 1px $ph-medium-grey;
          margin-bottom: 1.5rem;
        }
      }

      .button {
        margin: .25rem 0 .5rem 0;

        @include xl-breakpoint {
          margin-right: 1rem;
        }
      }

      .hhr:first-child {
        margin-top: 2rem;
      }

      &__cols {
        
        & > [class*=col-] {
          margin-bottom: 1rem;

          @media #{$large-up} {
            margin-bottom: 1.5rem;
          }

          @media #{$large-up} {
            padding-right: 1rem;
          }
        }

        .col-12, .col-md-12, .col-lg-12 {
          padding-right: 0;
        }

        p {
          margin-bottom: 1.25rem;

          @include large-breakpoint {
            min-height: 90px;
          }
        }

        & + h6 {
          @include large-breakpoint {
            margin-top: 2.5rem;
          }
        }

        .em {
          display: block;
          font-weight: 700;
          margin: 1rem 0;
        }

        
        a.button {
          
          @media #{$medium-up} {
            margin-top: .625rem;
          }

          @media #{$xlarge-up} {
            margin-top: 1rem;
            width: 100%;
          }

          @media #{$xxlarge-up} {
            width: 75%;
          }
        }

        .col.col-md-6 {
          a.button {
            min-width: 170px;

            @media #{$large-up} {
              width: 100%;
            }
          }
        }

        .col.col-xl-3 {
          a.button {
            @media #{$large-up} {
              width: 100%;
            }
          }
        }

        h3 {
          margin-bottom: 0;

          & + h3,
          & + h2,
          & + p {
            margin-top: .5rem;

            @media #{$medium-up} {
              margin-top: 1rem;
            }
          }
        }
      }
    }

    &__directory {
      .hhr {
        margin: 1rem 0 0 0;

        @media #{$large-up} {
          margin: 2.5rem 0 0 0;
        }
      }

      & [class*=col-] {
        margin-bottom: 0;
      }

      .col .button:first-child {
        margin: 0 0 2rem 0;

        @media #{$small-up} {
          margin: 0 0 2rem 0;
        }

        @media #{$large-up} {
          margin: .5rem 0 2rem 0;
        }

        @media #{$xlarge-up} {
          margin: 1.5rem 0 2rem 0;
        }
      }
    }
  }
}

// Sub-sections of content 
// @use <div>, <fieldset>, <article>
.ph-content-section {
  &__header {
    padding: .5rem 0;
    margin: 0;
  }
  
  &__title {
    padding: 0;
    margin-bottom: .25rem !important;
  }

  &__subtitle {
    font-size: $sm-body-font-size;
    margin-bottom: 1.5rem !important;
  }

  &__image--center {
    margin: auto;    
    display: block;
  }
}