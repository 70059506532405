
// HTML base
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: $font-style $font-variant $font-weight #{$font-size}/#{$line-height} $font-family;
  font-size: $font-size;
  height: 100%;
}

// Body base
body {
  color: $font-color;
  background: $background !important;
  font-size: $body-font-size;
  height: 100%;
}

p {
  margin: 0 0 1rem 0;

  @media #{$medium-up} {
    margin: 0 0 .25rem 0;
  }

  em {
    font-weight: 500;
  }
}

ol,
ul,
dl,
table {
  margin: 0 0 0 0;
}

// Remove extra margin for nested lists
ul li ul {
  margin-bottom: 0;
}

ol li ol {
  margin-bottom: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

// Heading individual styles
h1 {
  font-size: $h1-mobile;
}

h2 {
  font-size: $h2-mobile;
  line-height: 1.125;
}

h3 {
  font-size: $h3-mobile;
  font-weight: 500;
}

h4 {
  font-size: $h4-mobile;
  font-weight: 500;
}

h5 {
  font-size: $h5-mobile;
}

@include small-breakpoint {
  h1 {
      font-size: $h1;
  }
  h2 {
      font-size: $h2;
  }
  h3 {
      font-size: $h3;
  }
  h4 {
      font-size: $h4;
  }
  h5 {
      font-size: $h5;
  }
}

//Heading margin tightener
.ht {
  margin-bottom: .25rem !important;

  & + .ht {
    margin: .25rem 0 !important;
  }
}

//Elastic headings
h2.vw {
  display: block;
  font-size: 9vw;
  margin-bottom: .5rem;

  @media #{$large-up} {
    font-size: calc(18px + 12 * ((96vw - 320px) / 1220));
  }
}

h3.vw {
  display: block;
  font-weight: normal;
  font-size: 7vw;

  @media #{$large-up} {
    font-size: calc(18px + 10 * ((72vw - 320px) / 1220));
  }
}


//Heading horizontal rule for section breaks
.hhr {
  border-bottom: solid 1px $ph-medium-grey;
  font-weight: 600;
  margin: 3rem 0 1.5rem 0;
  padding: .5rem 0;

  @include large-breakpoint {
    border-bottom: none;
    border-top: solid 1px $ph-medium-grey;
    margin: 5rem 0 1.5rem 0;
  }

  small {
    background-color: $highlight;
    font-size: rem-calc(13);
    font-weight: 500;
    float: right;
    padding: rem-calc(4);
    position: relative;
    text-transform: uppercase;
    bottom: -16px;
    white-space: nowrap;

    @media #{$large-up} {
      bottom: -4px;
      padding: rem-calc(4 8);
    }

    .fas {
      margin-right: 4px;
    }
  }
}

// Link styling
a {
  cursor: pointer;
  @include default-link($link, $visit, $hover, $active);
}


// Highlight
mark {
  background: $highlight;
  padding: 0 0.2rem;
}

// Blockquote
blockquote {
  margin: 0 0 $margins 0;
  border-left: $bq-border;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  cite {
      display: block;
      margin-top: $margins;
      font-size: 1rem;
      text-align: right;
  }
}

// Code block styling
pre {
  border: 0;
  border-radius: $border-radius;
  background: $code-background;
  padding: 1rem;
  tab-size: 2;
  color: $code-color;
  font-family: $code-family;
  font-size: $code-size;
  margin: 0 0 $margins 0;
  code {
      font-family: $code-family;
      line-height: 1.2;
  }
}

// Keyboard input
kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4;
  margin: 0 .1em;
  padding: .1em .6em;
  text-shadow: 0 1px 0 #fff;
}

// Inline code styling
:not(pre)>code {
  color: $code-color;
  background: $code-background;
  font-family: $code-family;
  font-size: $code-size;
  padding: 0 0.2rem;
  border: $borders;
  border-radius: $border-radius;
}

//hide element for toggle
.hidden {
  display: none;
}

// Line break
hr {
  height: 0;
  border: 0;
  border-top: $borders;
}

// Definition list
dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
}

// Full container 
.full-container {
  max-width: 100%;
  padding: 0 1rem;
}

// Container
.container {
  max-width: $large;
  padding: 0 $padding;
  margin-left: auto;
  margin-right: auto;
}

// Small container
.small-container {
  @extend .container;
  max-width: $small;
}

// Medium container
.medium-container {
  @extend .container;
  max-width: $medium;
}
