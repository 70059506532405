@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin position ($position: relative, $coordinates: 0 0 0 0) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);

    position: $position;

    @if $top == auto {
        top: $top;
    }
    @else if not(unitless($top)) {
        top: $top;
    }
    @if $right == auto {
        right: $right;
    }
    @else if not(unitless($right)) {
        right: $right;
    }
    @if $bottom == auto {
        bottom: $bottom;
    }
    @else if not(unitless($bottom)) {
        bottom: $bottom;
    }
    @if $left == auto {
        left: $left;
    }
    @else if not(unitless($left)) {
        left: $left;
    }
}

// MIXINS FOR COMPARE
////////////////////

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
    @if $xy == xy {
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      @include transform(translateX(-50%) translateY(-50%));
    }
    @else if $xy == x {
      left: 50%;
      right: auto;
      @include transform(translateX(-50%));
    }
    @else if $xy == y {
      top: 50%;
      bottom: auto;
      @include transform(translateY(-50%));
    }
  }
  
  // antialiasing mode font rendering
  
  @mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

// super light grid - it works with the .cd-container class inside style.scss

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

// Layout
///////////////////

// breakpoints
$S:     480px;   
$M:     768px;     
$L:     1170px;     

// media queries
@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}

//Sprite Icon Positions of Backgrounds
@mixin sprite-position($x:0, $y:0) {
  .sprite {
    background-position: $x $y;
  }
}

//Rotate an element param degrees
@mixin rotate($deg : 45) {
  -webkit-transform:rotate($deg+deg);
  -moz-transform:rotate($deg+deg);
  transform:rotate($deg+deg);
}

//Vertical Alignment of column content using Flexbox
.v-align {
  & > [class*="col-"] {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      align-self: center;
      max-width: 100%;
      flex-shrink: 0;
    }
  }
}

//Hide stuff
.hide {
  display: none;
}

//Flip horizontal
.flip {
  transform: scaleX(-1);
}