/* Buttons
  =============== */

// Main button styling
%buttons {
    -webkit-appearance: none;
    display: inline-block;
    border: $button-border-width $button-border-style $button-border-color;
    border-radius: $button-border-radius;
    background: $button-background;
    color: $button-color;
    font-weight: $button-font-weight;
    font-family: $button-font-family;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
    padding: .75rem 1rem;
    margin: 0 0 .5rem 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
    min-width: 120px;

    @media #{$medium-up} {
        min-width: 150px;
        padding: 1rem 1.25rem;
    }
}

// Buttons on hover
%buttons-hover {
    border: $button-border-width $button-border-style darken($button-border-color, 10%);
    background: $button-background-hover;
    color: $button-color;
    text-decoration: none;
}

// Buttons on focus
%buttons-focus {
    border: $button-border-width $button-border-style darken($button-border-color, 10%);
    background: darken($button-background, 10%);
    color: $button-color;
    text-decoration: none;
}

// Apply styles to .button class, button element, and button-based inputs
#{$buttons} {
    @extend %buttons;
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    &:hover {
        @extend %buttons-hover;
    }
    &:focus,
    &:active {
        @extend %buttons-focus;
    }
}

// Secondary button color
.standard-button,
a.standard-button {
    color: #fff;
    background: #000;
    border:none;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background: $ph-dark-grey;
        border:none;
    }
}

// Secondary button color
.accent-button,
a.accent-button {
    color: $accent-button-color;
    border: 2px solid $ph-gold;
    background: $accent-button-background;
    &:hover,
    &:focus,
    &:active {
        color: $ph-dark-grey;
        border: 2px solid $button-background-hover;

        &.chat:after {
            background: $button-background-hover;
            border-color: $button-background-hover;
            color: $button-background-hover;
        }
    }

    &.chat {
        margin:20px 0;
        position: relative;

        &:after {
            background-color: $background;
            border: solid 2px;
            border-left-color: $ph-gold;
            border-bottom-color: $ph-gold;
            border-right-color: transparent;
            border-top-color: transparent;
            border-bottom-width: 3px;
            display: block;
            color: $ph-gold;
            content: ".";
            position: absolute;
            -ms-transform: skewY(-40deg); /* IE 9 */
            transform: skewY(-40deg);
            font-size: 1.2rem;
            height: 18px;
            width: 19px;
            line-height: 1;
            right: 20%;
            text-indent: 16px;
            bottom: -11px;
        }

        & i {
            padding-right:10px;
        }
    }

    &.small-button.chat:after  {
        right: 10%;
        top: 16px;
    }
}

.plain-button,
a.plain-button {
    color: $ph-dark-grey;
    border: 2px solid $ph-grey;
    background: $background;

    &:hover,
    &:focus,
    &:active {
        background: $background;
        border: 2px solid $button-background-hover;
    }
}

// Muted buttons
.muted-button,
a.muted-button {
    background: $muted-background;
    border: $muted-border;
    color: $muted-color;
    &:hover,
    &:focus,
    &:active {
        color: $muted-color-hover;
        border: $muted-border-hover;
        background: $muted-background-hover;
    }
}

// Round buttons
.round-button,
a.round-button {
    border-radius: $round-buttons;
}

// Square buttons
.square-button,
a.square-button {
    border-radius: 0;
}

// Full width buttons (block level)
.full-button,
a.full-button {
    display: block;
    width: 100%;
}

// Small buttons
.small-button,
a.small-button {
    font-size: .875rem;
    font-weight: 600;
    padding: .375rem .75rem .25rem .75rem;
    margin: 0;

    @media #{$xxlarge-up} {
        padding: .375rem 1.375rem .25rem 1.375rem; 
    }
}

//Medium Buttons
.medium-button, 
a.medium-button {
    font-size: 1rem;
    font-weight: 500;
    padding: .5rem 1.5rem; //8px top/bottom X 24px left/right
    margin: 0;
}

// Icon text links
a.underline-link {
   @include underline-link($link, $visit, $hover, $active);
 }

 //Basic Close Icon (Hairline)
 .ph-close {
    display: block;
    height: 24px;
    width: 24px;
    position: relative;

    &:before,
    &:after{
        content:'';
        position:absolute;
        width: 24px;
        height: 2px;
        background-color: $ph-warm-grey;
        top: 12px;
    }

    &:before{
        -webkit-transform:rotate(45deg);
        -moz-transform:rotate(45deg);
        transform:rotate(45deg);
    }
    &:after{
        -webkit-transform:rotate(-45deg);
        -moz-transform:rotate(-45deg);
        transform:rotate(-45deg);
    }

    &:hover {
        &:before,
        &:after{
            background-color: $ph-gold
        }
    }
}

// Pinned Chat button


.ph-chat-container {
    position: fixed;
    bottom:20px;
    right:20px;
    z-index:20;
  }
  .ph-chat-button {
    display:block;
    background: #ffb91d;
    border-radius: 100px;
    transition: box-shadow .3s;

    &:hover {
        background:#e9a100;
        box-shadow: 0 0 5px rgba(00,00,00,.6); 
    }

    & img {
        padding:15px;
    }
  }