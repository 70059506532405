/* Tables
  =============== */
// Table expands to full width of containing element
thead tr:hover {
    background-color: transparent !important;
}
.ph-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
    background-color: $ph-lighter-grey;

    &__header {
        background-color: $ph-lighter-grey;
        background-color: #f9f9f8;
        border: 1px solid $ph-medium-grey;

        &--browse {
            border-top:none;
            border-left:none;
            border-right:none;
            border-bottom: 1px solid $ph-medium-grey;
        }
    }

    &__title {
        font-weight: $font-weight;
        padding:.5rem 2rem .5rem .75rem;
    }

    &__cell {
        padding:0 .75rem;

        &--tbpadding-1 {
            padding:1rem 0;
        }
    }

    &__row {
        border:none;
        background-color: $ph-lighter-grey;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            background-color: $ph-medium-grey;
            
            // &:first-child {
            //     background-color: transparent;
            // }
        }
    }
}

// Extra border underneath header
// thead th {
//     border-bottom: 2px solid $border-color;
// }

// Extra border above footer
tfoot th {
    border-top: 2px solid $border-color;
}

th,
td {
    text-align: left;
    padding: .25rem;
}

caption {
    padding: $padding 0;
    caption-side: bottom;
    color: $caption;
}

.striped-table tbody tr:nth-child(odd) {
    background-color: $stripes;
}

// Container for responsive tables
.contain-table {
    overflow-x: auto;
}

@include small-breakpoint {
    .contain-table {
        width: 100%;
    }
}

// Borderless table
.borderless td, .borderless th {
    border: none;
}


// Shipp[ing Status

$acknowledged:#0076CC;
$accepted:#FF6600;
$shipped:#669900;
$held:#9933CC;
$delayed:#CC3333;

// Blue: #0076CC
// Orange: #FF6600
// Teal: #00AAAc
// Green: #669900
// Purple: 9933CC
// Red: #CC3333



.acknowledged {color:$acknowledged;font-weight: 700;}
.accepted {color:$accepted;font-weight: 700;}
.shipped {color:$shipped;font-weight: 700;}
.held {color:$held;font-weight: 700;}
.delayed {color:$delayed;font-weight: 700;}


// Edit Table columns

.edit-columns-button {
    & {
        cursor: pointer;
        display: inline-block !important;
        position: relative;
        // color: $link !important;
        text-decoration: none;
        // border:none !important;
        background:#000 !important;
        padding:5px 0 0 0 !important;
        font-size: .875rem !important;
        font-weight: 400 !important;
        text-align: center !important;
        line-height: 1 !important;
        color:#fff !important;


  
        // &::after {
        //   content: '';
        //   position: absolute;
        //   width: 100%;
        //   transform: scaleX(0);
        //   height: 1px;
        //   bottom: 0;
        //   left: 0;
        //   background-color: $ph-gold;
        //   transform-origin: bottom right;
        //   transition: transform 0.25s ease-out;
        // }
        
        
        &:visited {
          color: $visit;
        }
        &:hover {
          color: $hover;  
          // text-decoration: underline;
          &::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          } 
        }
        &:active {
          color: $active;
        }
        &:focus {
            outline: none !important;
        }
      }
}

.js-editColButton {
    background-color: #000 !important;
    padding:0 .75rem !important;
    border-radius: 50px !important;
}



// Show More Bar
.show-more-bar {
    border-top:1px solid $ph-medium-grey;
    display: flex;
    justify-content: space-between;
    margin:0;
    padding:.5rem .5rem;
    align-items: center;
    height: 30px;

}


// PDP Page CAD Section
#CAD table.parts {
    width: 100%;

    tr td {
        display: inline-block;
        width:100px;
    }

    tr:first-child {
        display:none;
    }

    tr:nth-child(2) td:first-child {
        display: none;
    }

    td a {
        cursor: pointer;
    }

    td a:before {
        content: '\f56d';
        font-family: $icon-font-family;
        font-weight: $icon-font-weight;
        margin-right: 10px;
        cursor: pointer;
    }
}


// FLEX TABLE
.ph-flex-table {
    width: 100%;

    &__header {
        padding: .65rem;
        background-color: $ph-lighter-grey;
        background-color: #f9f9f8;
        border: 1px solid $ph-medium-grey;
        text-align: center;
        font-weight: 700;
    }

    &__row {
        width: 100%;
        padding: .25rem;
        text-align: center;
    }

    &__item {
        flex:1;

        &--icon-end {
            text-align: center;
            padding:0;
        }
    }
}

@media only screen and (min-width:576px) {
    .ph-flex-table {
        &__header {
            padding: .65rem;
            text-align: left;
        }

        &__row {
            text-align:left;
            padding: .65rem;
        }
    }

    .ph-flex-table__item--icon-end {
        text-align: right;
        padding-right: 1rem;
    }
}