/* Feedback Surveys
  =============== */

.search-survey {
    width:100%;
    text-align: center;
    padding: .5rem 0;
    font-size: 1.25rem;
    font-family: Roboto,Arial,sans-serif;

    a {
        font-size: 2rem;
        color:$ph-grey;
    }

    a:hover,
    a:active,
    a:focus {
        color: $ph-gold;
    }

    &_row {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    &_links {
        padding: .5rem;
    }
}

#phwa-searchResultFeedback_3 {
	margin: 10px 0 !important;
}
#phwa-searchResultFeedback_5 {
	margin: 10px 0 !important;
}
#phwa-searchResultFeedback_10 {
	margin: 10px 0 !important;
}