.jumbotron {
    margin: 0 auto;
    max-width: rem-calc(960);
    text-align: center;

    h1 {
        font-size: 1.5rem;
        line-height: 1.25;
        margin-top: 1rem;

        @media #{$small-up} {
            font-size: 2rem;
        }

        @media #{$medium-up} {
            font-size: 2.5rem;
        }

        @media #{$large-up} {
            margin-top: 2rem;
        }
    
        @media #{$xlarge-up} {
            font-size: 3rem;
        }

        &.ht {
            margin-top: 1.375rem !important;
        }
    }

    h2 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25;

        @media #{$medium-up} {
            font-size: 1.5rem;
        }

        @media #{$large-up} {
            margin-top: 1.75rem;
        }
    
        @media #{$xlarge-up} {
            font-size: 2rem;
        }
    }

    h3 {
        color: $ph-dark-grey;
        font-weight: 400;
    }

    p {
        font-size: rem-calc(16);

        @media #{$xlarge-up} {
            font-size: rem-calc(18);
        }
    }

    ul {
        padding: 0 0 1.5rem 1.25rem;
        
        li {
            font-size: rem-calc(16);

            @media #{$xlarge-up} {
                font-size: rem-calc(18);
            }
        } 
    }

    // Font Awesome 5 Pro Icons
    // See https://fontawesome.com/icons?d=gallery&m=free 
    .fab {
        font-size: rem-calc(100);
        margin-top: rem-calc(30);

        @media #{$medium-up} {
            font-size: rem-calc(120);
            margin-top: rem-calc(40);
        }

        @media #{$large-up} {
            font-size: rem-calc(140);
            margin-top: rem-calc(60);
        }

        @media #{$xlarge-up} {
            font-size: rem-calc(160);
            margin-top: rem-calc(80);
        }
    }

    &.text-left {
        text-align: left;
    }

    //Larger Card link inside Jumbotron, only supports a single large card currently
    & .ph-card-basic__link {
        margin: 3rem 1rem;
        max-width: 430px;
        min-height: 180px;

        @media #{$small-up} {
            margin: 3rem auto 2rem auto;
        }

        @media #{$medium-up} {
            font-size: rem-calc(21);
            margin: 3rem auto 3rem auto;
            min-height: 220px;
        }

        @media #{$large-up} {
            font-size: rem-calc(24);
            margin: 4rem auto 3rem auto;
        }

        @media #{$xlarge-up} {
            margin: 5rem auto 3rem auto;
        }
    }

    &.look-up {
        @media #{$large-up} {
            position: relative;
        }

        &:before {
            background-image: url(../img/look-up-arrow.png);
            content: "";
            display: none;
            height: 150px;
            width: 110px;
            position: absolute;
            right: -50px;
            top: -55px;

            @media #{$medium-up} {
                display: block;
                top: -40px;
            }

            @media #{$large-up} {
                right: -25px;
            }

            @media #{$xlarge-up} {
                right: -55px;
                top: -25px;
            }
        } 
    }
}

h5 {
    em {
        font-weight: 600;
    }
}