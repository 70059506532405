// We use this to do clear floats
@mixin clearfix {
    &:before, &:after { content: " "; display: table; }
    &:after { clear: both; }
}

/* Helpers
  =============== */
  ::selection{
    background: #333;
    color: #fff;
    text-shadow: none;
  }
  ::-moz-selection{
    background: #333;
    color: #fff;
    text-shadow: none;
  }
  ::-webkit-selection{
    background: #333;
    color: #fff;
    text-shadow: none;
  }

  // Classic clearfix 
.clearfix::before,
.clearfix::after {
    content: " ";
    display: block;
}

.clearfix:after {
    clear: both;
}

// Text styling
.ph-text-body__small {
    font-size: $sm-body-font-size;
    font-style: $sm-body-font-style;
    font-weight: $sm-body-font-weight;
    color: $sm-body-font-color;
    font-family: $sm-body-font-family;
    line-height: $sm-body-line-height;
}

// Multi Columns Body Text
.ph-text-body {
    // background-color: #ffffff;
    margin-bottom: 1rem;
    padding: 0 .5rem;

    &__twoCol {
        -webkit-columns: 2 200px;
        -moz-columns: 2 200px;
        columns: 2 200px;
    }
}

.ph-italic


// .ph-text-heading--large {}
// .ph-text-heading--medium {}
// .ph-text-heading--small {}

// Text alignment
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

// Text Weight

.bold {
    font-weight: 700;
}

// Display
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

// Vertical center
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Responsive images
.responsive-image {
    max-width: 100%;
    height: auto;
}

.fill-image {
    width: 100%;
    height: auto;
}

// Display State
.show-mobile {
    display: block !important;
}

.hide-mobile {
    display: none !important;
}

.hide-mobile-lg {
    display: none !important;
}
@media only screen and (min-width:768px) {
    .hide-mobile {
        display: block !important;
    }

    .show-mobile {
        display: none !important;
    }
}

@media only screen and (min-width:1200px) {
    .hide-mobile-lg {
        display: block !important;
    }

    .show-mobile-lg {
        display: none !important;
    }
}

.invisible {
    visibility: hidden;
}

// Borders
.border-left {
    border-left: none;
}

.border-botom {
    border-bottom: 1px solid $ph-medium-grey !important;
}

@media only screen and (min-width:768px) {
    .border-left {
        border-left: 1px #dedede solid;
    }
}

// Floats
.float-left {
    float: left;
}

.float-right {
    float: right;
}

// Padding
.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.padding-top {
    padding-top: 2rem;
}

.padding-bottom {
    padding-bottom: 2rem;
}

// Margins
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-top {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}

// Backgrounds
.alternate-background {
    background: $alternate-background;
    color: $alternate-color;
}

.ph-icon-gold {
    color: $ph-gold;
}

// Screen reader text for accessibility
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.tooltip-inner {
    background-color: $ph-dark-grey;
    opacity: .75;

    .arrow::before {
        background-color: $ph-dark-grey;
        opacity: .75;
      
    }
}


// Links
.a-no-underline {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    
}

.ph-blue-link {
    color: #0076CC !important;
    font-weight: 400;
  }


  //White space
  .wrap-text {
      white-space:normal !important;
  }


//   MyParker Private Assets
.ribbon {
    margin: 0;
    padding: 0;
    background: $ph-gold;
    color:$ph-dark-grey;
    padding:.15em 0;
    position: absolute;
    top:0;
    left:0;
    transform: translateX(0%) translateY(120%) rotate(-45deg);
    transform-origin: top left;
  }
  .ribbon:before,
  .ribbon:after {
    content: '';
    position: absolute;
    top:0;
    margin: 0 -1px; /* tweak */
    width: 100%;
    height: 100%;
    background: $ph-gold;
  }
  .ribbon:before {
     left:100%;
  }
  
  .ribbon:after {
    right:100%;
  }

//Directional section arrow
.ph-caret-down {
    position: relative;

    &:before {
        content: "";
        top: -7px;
        left: -7px;
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-left: solid 1px $ph-warm-grey;
        border-top: solid 1px $ph-warm-grey;
        transform: rotate(225deg) skew(-3deg,-3deg);
    }
}

.ph-caret-up {
    position: relative;

    &:before {
        content: "";
        top: -7px;
        left: -7px;
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-left: solid 1px $ph-warm-grey;
        border-top: solid 1px $ph-warm-grey;
        transform: rotate(45deg) skew(-3deg,-3deg);
    }
}