.ph-content-section {
    .row .ph-sidebar {
        padding: 0 1rem;
        width: 100%;

        @media #{$large-up} {
            float: left;
            width: 40%;
        }

        @media #{$xlarge-up} {
            width: 35%;
        }

        @media #{$xxlarge-up} {
            width: 30%;
        }

        h1, h2, h3 {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        &-content {
            padding: 0 1rem;
            width: 100%;

            @media #{$large-up} {
                float: left;
                width: 60%;
            }

            @media #{$xlarge-up} {
                width: 65%;
            }

            @media #{$xxlarge-up} {
                width: 70%;
            }

            .px-extra,
            .py-extra {
                @media #{$medium-only} {
                    padding: 0;
                }

                @media #{$large-only} {
                    padding: 0;
                }
            }
        }

        &-entry {
            padding: 1.5rem 1rem;
            margin-bottom: 1.5rem;

            @media #{$large-up} {
                padding: 2rem;
            }

            @media #{$xlarge-up} {
                margin-bottom: 2rem;
                margin-right: 1rem;
            }

            @media #{$xxlarge-up} {
                margin-right: 3rem;
            }

            &-fluid {
                margin-bottom: 1.5rem;

                @media #{$xlarge-up} {
                    margin-bottom: 2rem;
                    margin-right: 1rem;
                }
    
                @media #{$xxlarge-up} {
                    margin-right: 3rem;
                }
            }

            &-menu {
                margin-right: 1rem;

                @media #{$medium-up} {
                    margin-right: 2rem;
                }

                @media #{$xlarge-up} {
                    margin-right: 3rem;
                }

                @media #{$xxlarge-up} {
                    margin-right: 4rem;
                }
            }
        }

        &-entry, &-entry-fluid {
            h1, h3 {
                margin-top: .5rem;
            }

            h3 {
                font-weight: 600;
            }

            p {
                margin-bottom: 1.25rem;

                @media #{$large-up} {
                    margin-bottom: 1.5rem;
                }
            }
        }

        &-aside {
            padding: 0 1rem 0 0;
            margin: 1.5rem 0;

            @media #{$large-up} {
                padding-right: 2rem;
            }

            @media #{$xlarge-up} {
                margin-bottom: 2rem;
                margin-right: 1rem;
            }

            @media #{$xxlarge-up} {
                margin-right: 3rem;
            }
        }
    }
}
