/* Product Compare
  =============== */

  
  .ph-compare-table {
    width: 100%;
    // border-top: 1px solid #ccc;
    // border-left: 1px solid #ccc;
    border-collapse: collapse;
    margin-bottom:1em;
    
    th, td {
      padding: 0.5em 1em;
      border-bottom: 1px solid #ccc;
      // border-right: 1px solid #ccc;
      white-space: pre;
    }

    td {
      padding: 1em 1em;
      border-bottom: 1px solid #ccc;
      // border-right: 1px solid #ccc;
      white-space: pre;
    }

    i {
      margin: auto;
    }
    
    thead th,
    tbody td {
      text-align: center;
    }
    
    // Custom Styles
    thead {
      color: $ph-dark-grey;
      
      th {
        padding: 1em;
      }
    }
    
    &[data-comparing="active"] tbody th {
      border-bottom: none;
      font-size: 0.75em;
      color: #767676;
      padding-bottom: 0;
    }
  
  }

  .gray {
    background-color: $ph-lighter-grey;
  }

