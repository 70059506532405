/* Clearfix
  =============== */

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

%clear {
  content: ' ';
  display: table;
}
.clear {
  *zoom: 1;
  &:before {
    @extend %clear;
  }
  &:after {
    @extend %clear;
    clear: both;
  }
}