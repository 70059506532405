/* Filters
  =============== */

//   .ph-facet-menu {
//     overflow-y: none; 
//     height: auto;

//     @include large-breakpoint {
//         overflow-y: scroll; 
//         height: calc(1000px);
//     }
//   }

.ph-filters {
    padding:0 0 30px;
}

  .ph-filter-block {
    margin: .5rem auto;
    width: 100%;
    font-family: $font-family;
    background: $background;

    &__item {
        background: $background;
        border-bottom: 1px solid $ph-medium-grey;

        &:first-child {
            border-top: 1px solid $ph-medium-grey;
        }
    }

    &__header {
        padding: 0.5rem .125rem .25rem .125rem;
        cursor: pointer;
        transition: .2s ease;
        margin:0;
        display:block;
        overflow: hidden;

        &:hover {
            background: $ph-light-grey;
        }
    }

    &__title {
        font-size: $body-font-size;
        font-weight: 700;
        color: $ph-dark-grey;
        margin:0;

        &::before {
            float:left;
            margin: .15rem .5rem .15rem 0;
            font-family: $icon-font-family;
            font-size: $sm-body-font-size;
            content: ' \f067'; //fa-plus
        }
    
        &.collapsed {
            &::before {
              content: '\f068'; //fa-minus
            }
          }
    }

    &__title--open {
        font-size: $body-font-size;
        font-weight: 700;
        color: $ph-dark-grey;
        margin:0;

        &::before {
            float:left;
            margin: .15rem .5rem .15rem 0;
            font-family: $icon-font-family;
            font-size: $sm-body-font-size;
            content: ' \f068'; //fa-minus
        }
    
        &.collapsed {
            &::before {
              content: '\f067'; //fa-plus
            }
          }

    }

    &__content {
        display: none;
        // padding: 1rem 1.5rem 2rem 1.5rem;
        color: $ph-dark-grey;
        line-height: 1.6;

        &--open {
            display: block;
        }
    }
  }

 
  .ph-filter-list, 
  ul.ph-filter-list {
      position: relative;
      list-style: none;
      padding: 0 1.25rem;

      &__item {
          position: relative;
          padding: 0;

          label {
              display: flex;
              align-items: baseline;
              margin: 0;
              padding:0;
              font-weight: $font-weight;
              cursor: pointer;

              &:hover {
                  text-decoration: underline;
              }
          }

          input {
              margin-right:.5rem;
          }

      }

      .ph-filter-list--border-bottom, 
      ul.ph-filter-list--border-bottom {
        border-bottom: 1px solid $ph-light-grey;
        padding:.15rem 0;
    }

    //   &__expand {
    //      margin-top: 15px;
    //      font-weight: 500; 
    //      cursor: pointer;
    //      text-decoration: underline;
    //   }
  }

  // View More / Less
  a.viewMore {
    display:block;
    font-size:.875rem;
    font-weight:700;
    margin-top:10px;
    color:#424242;
    text-decoration:underline;
    &::after {
      content:'+ View More';
    }
    &.viewLess::after {
      content:'- View Less';
    }
    &:hover {
        color: lighten(#424242, 15%);;
    }
  }

// Modifiers
/////////////////////////
.ph-filter-group {
    padding:.5rem 0;

    // Tabbed Filters
    &__tabbed {
        padding:1rem;

        &--list {
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: hidden;
            // border-bottom: 1px solid #999;

            li {
                float: left;
                border: 1px solid #999;
                background: $background;

                a {
                    display: block;
                    padding: .25rem 1rem;
                    font-size: 0.875rem;
                    color: $ph-dark-grey;
                    text-decoration: none;

                    &:hover {
                        background: $ph-gold;
                    }
                  }
              }
            li.active {
                font-weight: bold;
                background: $ph-gold;
            }
        }

        &--container {
            // border: 1px solid #999;
            border-top: none;
            background: #fff;
        }

        &--content {
            padding: 0 .25rem;
            font-size: 0.875rem;
        }

        .ph-filter-list, 
        ul.ph-filter-list {
            padding:.5rem 0;
        }
    }
    // End Tabbed Filters
}



// Vertical Side Navigation
.ph-filter-block-gold {
    border: 1px solid #dddddd;	
    background-color: #FFFFFF;
    margin-bottom:25px;
    // padding-bottom: .5rem;

    &__header {
      background-color: #ffb91d;
      font-size: $body-font-size;
      font-weight: 700;
      line-height: 21px;
      padding: .5rem 0 .5rem .5rem;

      i {
        margin-right:.5rem;
      }

    }

    &__content {
        position: relative;
        padding: .5rem 2rem 0;
        // input, button {
        //     display: inline-block;
        // }
    }

    &__form-group {
        display: flex;
        // padding:.5rem .25rem 0 .25rem;
        

        input {
            border-radius: none;
            border: none;
            font-size: .875rem;
            // flex: 1;

            &[type=text] {
                padding: 0;
            }

            &:hover,
            &:focus,
            &:active {
                border: none;
                box-shadow: none;
            }
        }

        span {
            // flex:1;
            padding:.5rem 1rem;
        }

        // &.inputaddon-field {
        //     flex: 1;
        // }
        // &.inputaddon-item {
        //     flex: 2;
        //     margin: 1rem;
        // }
    }


    &__list {
      padding: .75rem;
      list-style: none;

      li {
        border-bottom:1px solid #dddddd;
        padding:.75rem .75rem .75rem .5rem;

        &:hover {
          background-color: #fdd880;
        }

        & a {
          color:#424242;
          font-size: $h4;	
          font-weight: 700;	
          line-height: 16px;
          text-decoration: none;
          display:block;
        }
      }
    }
  }

// Selected Filters
.ph-selected-filters-list {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;

    &__item i {
        padding: .25rem 0;
        color: $ph-medium-grey;
        position: absolute;
        left:10px;
        width: 100%;
    }

    &__item a:hover {
        color:$ph-dark-grey;
    }

    &__item a i:hover {
        color:$error;
    }
}

.ph-clear-filters {
    position: absolute;
    right:30px;

    a {
        font-weight: $font-weight;
        color: $ph-dark-grey;

        &:hover {
            color: $ph-dark-grey; 
        }
    }
}


