.ph-header-main {
  &__primary {
    border-bottom: solid 2px $ph-gold;
  }

  &__secondary {
    background-color: $black;
  }

  &__title {
    background-color: $ph-lighter-grey;

    h1 {
      margin: rem-calc(15 0);
      line-height: 1.125
    }
  }
}

.ph-header {
  padding: 0;
  margin: 0;

  &__description-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__description-image-container {
      padding:.5rem;
      max-width: 200px;
      width:100%;

    &__description-image {
      
    }

  }

  &__description-content-container {
    padding:.5rem;
    max-width: 800px;

    &__description-content {
      
    }
  }
}

#phHeaderStickyNav {
  background-color: $white;
}
