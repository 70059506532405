
/* Images
  =============== */
img {
    max-width: 100%;
  }
  
  // Simple Lightbox
  
  .lightbox-opened {
    background-color: #333;
    background-color: rgba(#333, 0.9);
    cursor: pointer;
    height: 100vh;
    left: 0;
    overflow-y: scroll;
    padding: 24px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index:5000;
    
    // &:after {
    //   color: $ph-dark-grey;
    //   font-family: "Font Awesome 5 Pro"; 
    //   font-weight: 900; 
    //   font-size: 1.25rem;
    //   content: "\f00d";
    //   padding: 6px 12px;
    //   margin-left:-40px;
    //   position: relative;
    //   text-transform: uppercase;

    // }
    
    img {
      box-shadow: 0 0 6px 3px #333;
      width: 100%;
      max-width:600px;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .chat-Icon-Div{
      z-index:1 !important;
    }
  }

  #lightbox-close {
      color: $ph-dark-grey;
      font-family: "Font Awesome 5 Pro"; 
      font-weight: 900; 
      font-size: 1.25rem;
      content: "\f00d";
      padding: 6px 12px;
      margin-left:-40px;
      position: relative;
      text-transform: uppercase;
  }

  .ph-product-image {
    color: #fff;
    display: inline-block;
    max-width: 160px;
    width: 100%;
    position: relative;	
    &::before {
      // background-color: rgba(0, 0, 0, .3);
      border-bottom: 1px solid #dedede;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &:hover {
      transition: width 1s ease;

      .inner-block:before,
      .slider-top-right:after {
        height: 100%;
      }
      .inner-block:after,
      .slider-top-right:before {
        width: 100%;
      }

      i {
        color:$ph-gold;
      }
    }
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      padding: 1.25rem;
    }
  }
  
  .block-content {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: .25rem;

    i {
      color:#dedede;
    }
  }
  
  .slider-top-right:before,
  .inner-block:after {
    height: 2px;
    transition: width .75s ease;
    width: 0%;
  }
  
  .slider-top-right:after,
  .inner-block:before {
    height: 0%;
    transition: height .75s ease;
    width: 2px;
  }
  
  .inner-block:before,
  .inner-block:after,
  .slider-top-right:before,
  .slider-top-right:after {
    background-color: $ph-gold;
    content: '';
    display: block;
    position: absolute;
  }
  
  .inner-block {
    font-size: 2em;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    &:before {
      bottom: 0;
      left: 0;
    }
    &:after {
      bottom: 0;
      right: 0;
    }
  }
  
  .slider-top-right {
    position: relative;
    width: 100%;
    height: 100%;
    &:before {
      top: 0;
      left: 0;
    }
    &:after {
      top: 0;
      right: 0;
    }
  }

/* Icons
  =============== */

//Larger Card Icons 120x120
[class*=ph-icon] {
  //card links with icons
  .sprite {
    background-image: url(../img/ph-card-icons.png);
    display: block;
    height: 120px;
    margin: 0 auto;
    width: 120px;
  }
}

.ph-icon {
  //sprite-position(x, y)
  &-docs {
    @include sprite-position(0, 0);
  }

  &-team {
    @include sprite-position(-120px, 0);
  }

  &-talk {
    @include sprite-position(-240px, 0);
  }
}

//FAQ Icons 80x80
[class*=ph-icons] {
  
  .sprite {
    background-image: url(../img/ph-faq-icons.png);
    display: block;
    height: 80px;
    margin: 0 auto;
    width: 80px;

    & + span {
      margin-top: rem-calc(20);
    }
  }

  & > a {
    display: block;
    margin: 2px;
    padding: rem-calc(20);
    width: 100%;

    &:hover {
      background-color: $ph-light-grey;
      text-decoration: none;
    }

    span {
      color: $ph-dark-grey;
    }
  }

  span {
    color: $black;
    display: block;
    font-size: rem-calc(16);
    text-align: center;
    margin-bottom: .5rem;
    width: 100%;

    @media #{$medium-up} {
      min-height: 34px;
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(18);
      min-height: auto;
    }
  }

  p {
    padding: .5rem;
    text-align: center;
  }
}

//sprite-position($x:0, $y:0) 80x80
.ph-icons {
  &-category {
    @include sprite-position(0, 0);
  }

  &-find {
    @include sprite-position(-80px, 0);
  }

  &-help {
    @include sprite-position(-160px, 0);
  }

  &-all {
    @include sprite-position(-80px, -80px);
  }

  &-part-manual {
    @include sprite-position(-240px, 0);
  }

  &-ordering {
    @include sprite-position(0, -80px);
  }
}

  // No Background Scroll
  
  .no-scroll {
    overflow: hidden;
  }



//Misc design elements
.ph-bg {
  &__gray {
    background-color: $ph-light-grey
  }

  &__img-block {
    img {
      border: solid 1px $ph-warm-grey;
      -khtml-box-shadow: 15px 15px 0px 0px #f6f6f4;
      -webkit-box-shadow: 15px 15px 0px 0px #f6f6f4;
      -moz-box-shadow: 15px 15px 0px 0px #f6f6f4;
      box-shadow: 15px 15px 0px 0px #f6f6f4;

      margin: rem-calc(0 0 30 -15);

      @media #{$large-up} {
        margin-bottom: 0;
      }
    }
  }

  &__part-match {
    background: url( ../img/parker-parts-match-bg.png ) 37% 50% no-repeat;
    background-size: contain;
  }

  &__exploded-cad {
    background-position: center 60%;
    background: url( ../img/ph-exploded-part-page.jpg ) no-repeat;
    background-size: contain;
    margin-top: 4rem;
    min-height: 550px;

    @media #{$medium-up} {
      margin-top: 5rem;
      min-height: 600px;
    }

    @media #{$large-up} {
      background-position: 40% center;
      background-size: auto;
      margin-top: 3rem;
      min-height: 630px;
    }

    @media #{$xxlarge-up} {
      background-position: center center;
    }

    cite {
      display: block;
      position: relative;
      top: -25px;
      font-style: normal;
      font-weight: 600;

      @media #{$large-only} {
        top: 0
      }

      @media #{$xlarge-up} {
        display: block;
        text-align: center;
        position: relative;
        top: -25px;
      }
    }

    &-info {
      align-self: flex-end;

      @media #{$large-up} {
        align-self: auto
      }

      @media #{$xlarge-up} {
        padding-right: 2rem !important;
      }

      @media #{$xxlarge-up} {
        padding-right: 5rem !important;
      }

      &:before {
        background-image: url(../img/look-up-arrow.png);
        content: "";
        height: 150px;
        width: 110px;
        position: absolute;
        right: 10px;
        top: -130px;

        @media #{$medium-up} {
          top: -100px;
        }

        @media #{$large-up} {
          display: none;
        }
      }
    }
  }
}
