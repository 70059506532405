$read-more-settings: (
  'rows': 10,
  'font-size': $body-font-size,
  'line-height': 1.4,
  'duration': 300ms,
  'easing': ease,
  'text-color': $ph-dark-grey,
  'trigger-color': $ph-dark-grey,
  'bg-color': #ffffff,
);

// Optimal height based on your font size and line-height
$read-more-height: map-get($read-more-settings, 'font-size') * map-get($read-more-settings, 'line-height') * (map-get($read-more-settings, 'rows') + 1); // + 1 for read-more


.ph-overflow {
  line-height: map-get($read-more-settings, 'line-height');

    &__read-more {
        position: relative;
        color: map-get($read-more-settings, 'text-color');
        text-decoration: none;
        cursor: text;
        margin-bottom: 20px;
        
        .trigger {
            display: block;
            position: absolute;
            bottom: 10px;
            left:0;
            cursor: pointer;
            color: map-get($read-more-settings, 'trigger-color');
            font-weight: 400;

            &:hover {
                color: $link-color;
                text-decoration: underline; 
            }
        }
        
        .collapse{
            display:none;
        }
        
        .content {
            position: relative;
            overflow: hidden;
            max-height: $read-more-height;
            -webkit-transition: max-height map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing');
            transition: max-height map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing');
            
            &::before {
                content: '';
                -webkit-transition: opactiy map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing'), visibility map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing');
                transition: opactiy map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing'), visibility map-get($read-more-settings, 'duration') map-get($read-more-settings, 'easing');
                background-image: -webkit-linear-gradient(rgba(255,255,255,0), map-get($read-more-settings, 'bg-color'), map-get($read-more-settings, 'bg-color'));
                background-image: linear-gradient(rgba(255,255,255,0), map-get($read-more-settings, 'bg-color'), map-get($read-more-settings, 'bg-color'));
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 3rem;
            }
    }

    //Larger overflow panel
    &.lg {
        .content {
            max-height: rem-calc(350);

            @media #{$large-up} {
                max-height: rem-calc(470);
            }
        }

        .ph-overflow__read-more-toggle {
            .icon-toggle {
                @extend .ph-caret-down;
            }
        }

        &.expand-text {
            .ph-overflow__read-more-toggle {
                .icon-toggle {
                    @extend .ph-caret-up;
                }
            }
        }
    }

    &:not(.lg) {
        .ph-overflow__read-more-toggle {
            .icon-toggle {
                display: inline-block;
                float: left;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;

                &::before {
                    content: "\f067";
                }
            }
        }

        &.expand-text {
            .ph-overflow__read-more-toggle {
                .icon-toggle::before {
                    content: "\f068";
                }
            }
        }
    }

    &.expand-text {
        .content {
            max-height: 100%; // great value for height animation
        }

        .content::before,
        .trigger {
            opacity: 0;
            visibility: hidden;
        }
        
        .collapse {
            display: block;
            //position: absolute;
            bottom: 30px;
            right:0;
            cursor: pointer;
            color: map-get($read-more-settings, 'trigger-color');
            font-weight: 400;
            text-align: left;
            margin-top: 30px;
        }

        .ph-overflow__read-more-toggle {
            .less {
                display: block;
            }

            .more {
                display: none;
            }
        }
    }
    }

    &__read-more-toggle {
        display: block;
        margin: rem-calc(16 0);

        &:hover {
            text-decoration: none;

            h4, h5 {
                text-decoration: underline;
            }
        }

        h4, h5 {
            margin: 0;
        }

        .less {
            display: none;
        }

        .more {
            display: block;
        }
    }
}