@mixin caret-down {
  @include rotate(135);
}

@mixin caret-up {
  @include rotate(-45);
}

@mixin caret-right {
  @include rotate(45);
}

@mixin caret-left {
  @include rotate(-135);
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      border-top: $caret-width solid $ph-grey;
      border-right: $caret-width solid $ph-grey;
      display: inline-block;
      height: 6px;
      width: 6px;
      margin-left: $caret-spacing;
      vertical-align: $caret-vertical-align;
      content: "";
      @if $direction == down {
        @include caret-down;
      } @else if $direction == up {
        @include caret-up;
      } @else if $direction == right {
        @include caret-right;
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $caret-spacing;
        vertical-align: $caret-vertical-align;
        content: "";
        @include caret-left;
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
