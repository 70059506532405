@mixin default-link($link, $visit, $hover, $active) {
    & {
      color: $link;
      text-decoration: none;

      &:visited {
        color: $visit;
      }

      &:hover {
        color: $hover;
        text-decoration: underline;
        text-decoration-color: $black;
      }
      &:active {
        color: $active;
      }
    }
  }
  

  @mixin underline-link($link, $visit, $hover, $active) {
    & {
      display: inline-block;
      position: relative;
      color: $link;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $ph-gold;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      
      
      &:visited {
        color: $visit;
      }
      &:hover {
        color: $hover;  
        // text-decoration: underline;
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        } 
      }
      &:active {
        color: $active;
      }
    }
  }

  .a-underline {
    text-decoration: underline;
    color:#0066cc;
    &:hover {
      color: #000000;
    }
  }