/* Fixed Header Nav
  =============== */
#phHeaderStickyNav {
  &:not(.outside) {
    .ph-navbar {
      .navbar-brand {
        margin: rem-calc(5 12 4 0);

        @media #{$xlarge-up} {
          margin: rem-calc(16 24 14 0);
        }
      }

      &__primary {
        //Primary nav
        .ph-navbar__primary_global {
            @media #{$large-up} {
              padding: rem-calc(30 0 0 0);
            }
          }

        //desktop header search
        .search-form-wrapper {
          position: static;

          @media #{$xlarge-up} {
            padding: rem-calc(10 0 32 0);
          }

          .search-form {
            display: block;

            .form-control {
              opacity: 1;
            }
            
          }

          .search-form-toggle {
            @media #{$xlarge-up} {
              display: none;
            }
          }
        }
      }
    }
  }

  &.fixed-top {
    .search-form-wrapper {
      top: 4px;
    }
  }

  &.outside {
    .ph-navbar {
      .search-submit {
        display: none;
        opacity: 0;

        @media #{$large-up} {
          display: block;
          opacity: 1;
        }
          
        @media #{$xlarge-up} {
          display: none;
          opacity: 0;
        }
      }
    }
  }

  &.search-open {
    .ph-navbar {
      &__primary {
        .navbar-wrapper {
          z-index: unset;
        } 
      }
    }

    .search-form-wrapper {
      margin: 10px 0 15px 0;

      @media #{$large-up} {
        margin: 0;
      }

      .search-form {
        .form-control {
          display: block;
          opacity: 1;
        }
      }

      .search-form-toggle {
        .fa-search:before {
          content: '\f00d'
        }

        @media #{$large-up} {
          display: none;
        }
      }

      .search-submit {
        display: inline;
        opacity: 1;
      }
    }
  }
}

/* General Navbar overrides
=============== */
.ph-header-main {
  
  .navbar-brand {
    line-height: 0.5;
    margin: rem-calc(5 12 4 0);
    padding: 0;
    transition: all 250ms ease-in-out;

    img {
      max-width: 110px;
      transition: all 250ms ease-out;

      @media #{$xlarge-up} {
        max-width: 200px;
      }
    }
  }

  //Navbar defaults
  .navbar-nav {
    li.nav-item {
      position: relative;

      a.nav-link {
        &:hover {
          text-decoration: none;
        }

        .count {
          background-color: $ph-gold;
          border-radius: 100%;
          color: $black;
          font-size: rem-calc(10);
          font-weight: 600;
          padding: rem-calc(2 4);
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;
    font-size: rem-calc(24);
    margin: 0 0 0 -15px;
    min-width: auto;

    @media #{$large-up} {
      margin: 0;
    }

    &:hover, &:focus {
      background-color: transparent;
      border: none;
    }

    &.close {
      color: $white;
      background-color: $black;
      position: absolute;
      z-index: 99999;
      right: 4px;
      top: 8px;
    }
  }

  .navbar-brand {
    align-self: flex-start;
    max-width: 120px;

    @media #{$medium-up} {
      max-width: none;
    }
  }
}

/*
* Primary nav
*/
.ph-navbar {
  justify-content: left;
  transition: all 250ms ease-in-out;

  &__primary {
    position: relative;
    width: 100%;

    @media #{$large-up} {
      width: auto;
    }

    @media #{$xlarge-up} {
      margin-left: rem-calc(48);
      transition: all 250ms ease-in-out;
    }

    .navbar-wrapper {
      position: relative;
      z-index: 888;
    }

    //desktop header search
    .search-form-wrapper {
      align-self: flex-start;
      margin-bottom: 0;
      width: 100%;
      transition: all 250ms ease-in-out;

      @media #{$xlarge-up} {
        position: absolute;
      }

      .search-form-toggle {
        background-color: $white;
        border: none;
        color: $black;
        display: inline;
        font-size: rem-calc(20);
        height: rem-calc(38);
        line-height: $input-line-height;
        margin: 0;
        min-width: rem-calc(40);
        padding: 0;
        position: fixed !important;
        right: 15px;
        top: 6px;

        //Was medium
        @media #{$large-up} {
          display: none;
          position: static !important;
        }

        @media #{$xlarge-up} {
          display: inline;
        }
      }
    }

    &_global {
      transition: all 250ms ease-in-out;

      li.nav-item {
        a.nav-link {
          display: block;
          font-size: rem-calc(17);
          font-weight: 500;
          padding: rem-calc(10 16);
        }

        &:first-child > a.nav-link {
          @media #{$xlarge-up} {
            padding-left: 0;
          }
        }

        .dropdown-menu {
          border: none;
          display: block;
          margin: 0;
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: max-height opacity 0.125s ease-in-out;
          padding: 0;

          & > .dropdown-menu-content {
            display: block;
            max-height: 0;
            transition: max-height 0.375s ease-in-out;
            overflow: hidden;
          }

          &.show {
            max-height: 900px;
            opacity: 1;
            overflow: visible;
            padding: rem-calc(0 0 12 0);

            & > .dropdown-menu-content {
              max-height: 900px;
            }

            @media #{$xlarge-up} {
              border: solid 1px $ph-grey;
              box-shadow: .125rem .25rem .375rem rgba($black, .175);
              margin-top: rem-calc(8);
              padding: rem-calc(12 0 0 0) !important;
            }
  
            &::before {
              @media #{$xlarge-up} {
                background-color: $white;
                border-top: 1px solid $ph-grey;
                border-right: 1px solid $ph-grey;
                content: "";
                display: inline-block;
                height: 14px;
                left: 11%;
                position: absolute;
                width: 14px;
                top: -8px;
                @include rotate(-45);
              }
            }
  
            &::after {
              @media #{$xlarge-up} {
                background-color: $ph-gold;
                content: "";
                display: block;
                height: 6px;
                margin-bottom: .5px;
                margin-top: rem-calc(12);
                width: 100%;
              }
            }
          }

          a.dropdown-item {
            font-size: rem-calc(14);
            font-weight: 500;
          }
        }
      }
    }

    .search-form {
      width: 100%;

      select, input, button {
        margin: 0;

        &:focus {
          position: relative;
          z-index: 555;
        }
      }

      #search-selector-content-type {
        width: 135px;

        @media #{$xlarge-up} {
          width: auto;
        }
      }

      .form-control {
        display: none;
        opacity: 0;

        @media #{$large-up} {
          display: block;
          opacity: 1;
        }

        @media #{$xlarge-up} {
          opacity: 0;
        }
      }

      .form-group {
        margin: 0;
        width: 100%;

        @media #{$xlarge-up} {
          width: 90%;
        }
      }

      .search {
        &__category {
          
        }

        &__keyword {
          margin-left: -1px;

          input {
            width: 100%;
          }
          
        }
    
        &__submit {
          .search-submit {
            color: $white;
            display: none;
            font-size: rem-calc(20);
            height: rem-calc(38);
            line-height: $input-line-height;
            min-width: rem-calc(40);
            opacity: 1;
            padding: 0;
            transition: opacity 125ms ease-out;

            @media #{$large-up} {
              display: inline;
            }
          }
        }
      }
    }
  }
  &__primary_global,
  &__secondary:not(.my-parker) {
    .nav-item {
      //border-bottom: solid 1px $ph-medium-grey;

      @media #{$xlarge-up} {
        border: none;
      }
    }
  }
}


/*
* Secondary nav
*/
.ph-navbar {
  justify-content: left !important;

  &__secondary {
    flex-flow: column-reverse !important;

    @media #{$xlarge-up} {
      flex-flow:wrap !important;
    }

    li.nav-item {
      position: relative;
  
      a.nav-link,
      &.nav-sep > span {
        align-items: center;
        display: flex;
        font-size: rem-calc(16);
        font-weight: 100;
        line-height: 1.125;
        padding: rem-calc(10 16);

        @media #{$xlarge-up} {
          color: $white;
          font-size: rem-calc(14);
          height: 50px;
        }
      }
      
      &.nav-item-med {
        a.nav-link {
          font-size: rem-calc(17);
          padding: rem-calc(16 12 14 12);
        }
      }
  
      &.nav-sep {
        display: none;

        @media #{$xlarge-up} {
          display: inline;
        }

        & > span {
          font-size: rem-calc(16);
          padding: rem-calc(0 6);
          display: flex;
        }
      }
  
      &.nav-item-lg {
        .nav-link {
          padding-left: rem-calc(48);
        }

        .fa-lg {
          font-size: rem-calc(24);
          left: rem-calc(16);
          position: absolute;
        }
      }
  
      a.dropdown-toggle {
        white-space: normal;
  
        &:after {
          @include caret-down();
          margin-left: 8px;
          margin-top: -4px;
          
          @media #{$xlarge-up} {
            position: absolute;
            right: 5px;
          }
        }
      }
  
      .dropdown-item-text {
        font-weight: 400;
        padding: rem-calc(0 16 4 16);

        @media #{$xlarge-up} {
          color: $white;
        }
      }
  
      .dropdown-item-text,
      .dropdown-menu {
        font-size: rem-calc(13);
        font-weight: 100;
      }
  
      .dropdown-menu {
        @include box-shadow(none);
        border: none;
        margin: 0;
        padding: rem-calc(0 0 12 0);

        @media #{$xlarge-up} {
          background-color: $black;
        }
  
        .dropdown-item {
          padding-bottom: 0;
          padding-top: 0;
  
          &:hover {
            background-color: transparent;
          }   
  
          &.form-check {
            padding: rem-calc(2 16 2 40);
  
            .far {
              left: rem-calc(16);
              position: absolute;
              top: rem-calc(5);

              @media #{$xlarge-up} {
                color: $ph-gold;
              }
            }
  
            .fa-circle {
              @media #{$xlarge-up} {
                color: $ph-gold;
              }
            }
            
            label {
              cursor: pointer;
              font-weight: 100;
              margin: 0;

              @media #{$xlarge-up} {
                color: $white;
              }
            }
            .form-check-input {
              margin-top: 3px;
  
              &:checked + .fa-circle:before {
                content: "\f192"; 
              }
            }
          }
        }
      }
    }

    &.cart {
      background-color: $black;
      flex-flow: wrap !important;
      padding: rem-calc(2 12 1 8);
      position: fixed;
      right: 0;
      top: 0;

      @media #{$xlarge-up} {
        //margin-left: rem-calc(20);
        position: static;
      }

      a.nav-link {
        color: $white;

        @media #{$xlarge-up} {
          color: $black;
        }
      }
    }

    &.my-parker {
      background-color: $black;
      flex-direction: column-reverse;

      @media #{$xlarge-up} {
        flex-direction: unset;
      }

      a.nav-link {
        color: $white;
        height: 50px;
      }

      .user > a {
        @media #{$xlarge-up} {
          max-width: 120px;
        }
      }

      .locale {
        & > a {
          @media #{$xlarge-up} {
            max-width: 220px;
          }
        }

        .dropdown-menu {
          background-color: $black;

          form {
            //display: flex;
            min-width: 300px;
            max-width: 450px;
            padding-bottom: rem-calc(20);
          }

          #findLocale {
            background-color: $black;
            border: none;
            border-bottom: solid 1px $ph-light-grey;
            color: $white;
            font-weight: 100;
            font-size: rem-calc(11);
            padding: rem-calc(4 4 4 24);
            margin: rem-calc(0 0 10 0);

            &::placeholder {
              color: $ph-light-grey;
            }

            & + .fa-search {
              left: rem-calc(20);
              position: absolute;
              top: rem-calc(14);
            }
          }

          .dropdown-item {
            padding: rem-calc(4 20);
            position: relative;

            .form-check-label {
              color: $white;
              font-weight: 100;
              font-size: rem-calc(11);
              padding: rem-calc(0 64 0 24);
              margin: 0;
            }

            i {
              color: $white;
            }

            .fa-square {
              float: left;
            }

            .form-check-input {
              float: left;

              &.loc,
              &.loc + i,
              &.favorite,
              &.favorite + i {
                margin: 0;
                position: absolute;
                top: rem-calc(6);
              }

              &.loc,
              &.loc + i {
                left: rem-calc(20);
              }

              &.favorite,
              &.favorite + i {
                right: rem-calc(20);
              }

              &.loc,
              &.favorite {
                opacity: 0;
                z-index: 888;
              }

              &:checked + .fa-square:before {
                color: $ph-gold;
                content: "\f14a";
              }

              &:checked + .fas {
                color: $ph-gold;
              }
            }
          }
        }
      }
    }    
  }
}

//Start IE Overrides
//INTERNET EXPLORER ONLY (RELIES ON BODY JS CLASS)
body.ie {
  #phHeaderStickyNav {
    &.search-open {
      .search-form-wrapper {
        padding: rem-calc(10 0 0 0);

        @media #{$medium-up} {
          margin: 10px 15px;
          padding: rem-calc(6 8 4 0);
        }

        @media #{$xlarge-up} {
          margin: 0;
          padding: 0;
        }

        .search-submit {
          @media #{$large-up} {
            display: inline !important;
          }

          @media #{$xlarge-up} {
            display: inline !important;
            opacity: 1;
          }
        }

        .search-form-toggle {
          display: inline;

          @media #{$xlarge-up} {
            display: none !important;
          }
        }

        .form-control {
          display: block;
          opacity: 1 !important;
        }
      }
    }

    &.outside {
      .ph-navbar__primary {
        @media #{$xlarge-up} {
          //margin-top: rem-calc(16);
        }

        .search-form-wrapper {
          z-index: 888;

          /*@media #{$xlarge-up} {
            margin-top: rem-calc(10);
          }*/

          .search-form-toggle {
            @media #{$xlarge-up} {
              display: inline;
            }
          }

          .form-control {
            @media #{$xlarge-up} {
              display: inline;
              opacity: 0;
            }
          }

          .search-submit {
            @media #{$large-up} {
              display: none;
            }
          }
        }
      }
    }
  }  

  .ph-navbar {
    //display: block !important;
    position: static;

    &__primary {
      @media #{$large-up} {
        width: 100%;
      }

      @media #{$xlarge-up} {
        display: inline-block;
      }

      .search-form-wrapper {
        width: 100%;
    
        @media #{$xlarge-up} {
          max-width: 600px;
        }
    
        .form-control {
          @media #{$large-up} {
            display: none;
            opacity: 0;
          }
    
          @media #{$xlarge-up} {
            display: block;
            opacity: 1;
          }
        }
    
        .search-form-toggle {
          @media #{$large-up} {
            display: inline;
            position: fixed !important;
          }
    
          @media #{$xlarge-up} {
            display: none;
            position: static !important;
          }
        }
    
        .search-submit {
          @media #{$large-up} {
            display: none;
          }
    
          @media #{$xlarge-up} {
            display: inline;
            opacity: 1 !important;
          }
        }
      }
    }

    .navbar-brand {
      left: 50px;
      position: fixed;
      top: 0;

      @media #{$xlarge-up} {
        position: static;
      }
    }

    .navbar-toggler { 
      float: left;
      padding: rem-calc(12);
    }
  }
}
//End IE Overrides for header menus

//Offset Menu for mobile
.ph-offset-menu {

  &__wrapper {
    background-color: rgba(0,0,0,0.8);
    display: none;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    position: fixed;
    z-index: 9999;

    &.on {
      .ph-offset-menu__nav {
        margin-left: 0;
      }
    }

    &.off {
      .ph-offset-menu__nav {
        margin-left: -350px;
      }
    }
  }

  &__nav {
    background-color: $white;
    flex-direction: column;
    flex-wrap: nowrap !important;
    min-height: 100%;
    justify-content: flex-start !important;
    margin-left: -350px;
    max-width: 340px;
    transition: all 250ms ease-in-out;

    .navbar-nav {
      width: 100%;
    }
  }

}

// Series Pagination
.ph-pagination {
  padding: 0;
  
  @media #{$xlarge-up} {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

&__container {
  margin: 0;
  padding: 0;
}

&__results-dropdown-container {
  margin-left: auto;

  @media #{$xlarge-up} {
    justify-content: flex-end;
  }
}

&__container,
&__results-dropdown-container {
  flex: 0 1 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: space-around;
  align-items: center;
  margin: rem-calc(30 0);

  @media #{$xlarge-up} {
    margin: rem-calc(40 0);
  }
}

&__page-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;

  margin-left: -10px;
  margin-right: -10px;

  @media #{$medium-up} {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
  }
}

&__page-item {
  display: list-item;

  &:first-child > a {
    @media #{$large-up} {
      background: url(../img/ph-arrow-left.png) no-repeat;
      background-position: left 5px center;
      margin-left: 0;
      padding-left: rem-calc(30);
    }
  }

  &:last-child > a {
    @media #{$large-up} {
      background: url(../img/ph-arrow-right.png) no-repeat;
      background-position: right 5px center;
      padding-right: rem-calc(30);
    }
  }
}

&__page-link {
  color: $ph-grey;
  border: solid 1px $white;
  display: inline-block;
  position: relative;
  display: block;
  font-size: rem-calc(16);
  padding: .625rem .55rem;
  line-height: 1.25;

  @media #{$large-up} {
    font-size: rem-calc(18);
    padding: .625rem .75rem;
  }

  &:visited {
    color: $ph-grey;
  }

  &.active-page {
    background-color: $ph-light-grey;
    color: $ph-dark-grey;
    margin: 0;
  }

  &:hover {
    border: solid 1px $ph-medium-grey;
    text-decoration: none;
  }
}

&__results-dropdown-label {
  color: $ph-grey;
  font-weight: $sm-body-font-weight;
  font-size: rem-calc(16);
  margin: 0 10px 0 0;
  padding: rem-calc(12 12 12 0);

  @media #{$large-up} {
    font-size: rem-calc(18);
  }
}
&__results-dropdown-select {
  font-size: $body-font-size;
  font-weight: $sm-body-font-weight;
  margin-bottom: 0;
  max-width: 70px;
}

&__vertical {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
      font-weight: 600;

      a {
          border: solid 1px $white;
          display: inline-block;
          line-height: 1;
          padding: rem-calc(4 6 2 6);

          &:hover {
              border: solid 1px $ph-medium-grey;
              text-decoration: none;
          }
          &.active {
              background-color: $ph-light-grey;
          }
      }
  }
}

&__vertical-right {
  position: relative;

  .ph-pagination__vertical {
      display: block;
      margin: 0;
      position: absolute;
      right: -50px;
      top: 0;

      @media #{$large-up} {
        right: -40px;
      }

      @media #{$xlarge-up} {
        right: -45px;
      }

      li {
        display: list-item;
      }
  }
}
}

.ph-nav-right-vertical {
  padding-right: rem-calc(30);

  @media #{$large-up} {
    padding-right: 0;
  }

  .ph-sidebar-content {
    margin-right: rem-calc(16);

    @media #{$large-up} {
      margin-right: 0;
    }
  }
}

.ph-tab-group {
  padding:1rem .5rem .5rem 0;
  color: $ph-dark-grey;

  &__item {
    border: 1px $ph-dark-grey solid;
    padding:.5rem;

    &:hover {
      text-decoration: none;
      background: $ph-gold;
    }
  }

}

.ph-tab-group>.active {
  background:$ph-gold;
}

.tab-content .tab-pane {
  display: none;
}

.tab-content  .active {
  display: block;
}

// Underlined Tabs
.ph-tab-group-underlined {
  padding:1rem .5rem .5rem 0;

  &__item {
    padding:.5rem 1.5rem;
    color: $ph-dark-grey;

    &:hover {
      text-decoration: none;
      border-bottom: 4px solid $ph-gold;
    }
  }
}

.ph-tab-group-underlined>.active {
  border-bottom: 4px solid $ph-gold;
  color: $ph-dark-grey;
  font-weight: 700;
}

.ph-content-nav {
  &__history {
    font-size: rem-calc(17);
    text-align: center;

    .back, .next, .return {
      margin-bottom: .5rem;

      @media #{$medium-up} {
        margin: .5rem;
      }
    }

    .back {
      float: left;
    }

    .next {
      float: right;
    }

    .return {
      display: block;

      @media #{$large-up} {
        display: inline-block;
      }
    }

    .help {
      display: inline-block;
      margin-bottom: 1rem;
      text-align: center;
      text-decoration: none;

      @media #{$medium-up} {
        bottom: -8px;
        margin-bottom: 0;
        position: relative;
      }

      &:hover {
        h3 {
          border-color: $ph-gold;
          color: $ph-gold;
        }
      }
    }

    h5 {
      line-height: 1.25;
      margin: .25rem 1rem .5rem 1rem;
    }

    a {
      h3, h4 {
        color: $ph-dark-grey;
        display: inline-block;
        line-height: 1.125;
        margin: .375rem 0 2rem 0;
        border-bottom: solid 1px $ph-dark-grey;
      }

      &:hover {
        h4 {
          border-color: $ph-gold;
        }
      }
    } 
  }
}